import Vue from 'vue'
import VueRouter from 'vue-router'
import { registerGuard } from './guard'
import MainLayout from '@/layout/MainLayout.vue'
import SplashScreen from '@/components/splash/SplashContainer'


const Report = () => import(/* webpackChunkName: "report" */'../views/Report.vue')
const Patient = () => import(/* webpackChunkName: "patient" */'../views/Patient.vue')
const Laboratory = () => import(/* webpackChunkName: "lab" */'../views/Laboratory.vue')
const Staff = () => import(/* webpackChunkName: "staff" */'../views/Staff.vue')
const Pharmacy = () => import(/* webpackChunkName: "pharmacy" */'../views/Pharmacy.vue')
const Checking = () => import(/* webpackChunkName: "checking" */'../views/Checking.vue')
const Telemed = () => import(/* webpackChunkName: "telemed" */'../views/TeleMed.vue')
const Support = () => import(/* webpackChunkName: "support" */'../views/Support.vue')
const Profile = () => import(/* webpackChunkName: "profile" */'../views/Profile.vue')
const Setting = () => import(/* webpackChunkName: "setting" */'../views/Setting.vue')
const Administrator = () => import(/* webpackChunkName: "administrator" */'../views/Administrator.vue')
const Screening = ()  => import(/* webpackChunkName: "screening" */'../views/Screening.vue')
const Inventory = () => import(/* webpackChunkName: "inventory" */'../views/Inventory.vue')
const Physician = () => import(/* webpackChunkName: "Physician" */'../views/Physician.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'home',
        meta: {
          authRequired: true
        }
      },
      {
        path: '/screening',
        name: 'SCREENING',
        component: Screening,
        meta: {
          authRequired: true
        }
      },
      {
        path: '/setting',
        name: 'SETTING',
        meta: {
          authRequired: true
        },
        component: Setting
      },
      {
        path: '/profile',
        name: 'PROFILE',
        meta: {
          authRequired: true
        },
        component: Profile
      },
      {
        path: '/physician',
        name: 'DOCTOR_ROOM',
        meta: {
          authRequired: true
        },
        component: Physician
      },
      {
        path: '/patient',
        name: 'PATIENT',
        meta: {
          authRequired: true
        },
        component: Patient
      },
      {
        path: '/laboratory',
        name: 'LABORATORY',
        meta: {
          authRequired: true
        },
        component: Laboratory
      },
      {
        path: '/report',
        name: 'REPORT',
        meta: {
          authRequired: true
        },
        component: Report
      },
      {
        path: '/inventory',
        name: 'INVENTORY',
        meta: {
          authRequired: true
        },
        component: Inventory
      },
      {
        path: '/shift',
        name: 'SHIFT',
        meta: {
          authRequired: true
        },
        component: Staff
      },
      {
        path: '/pharmacy',
        name: 'PHARMACY',
        meta: {
          authRequired: true
        },
        component: Pharmacy
      },
      {
        path: '/checking',
        name: 'CHECKING',
        meta: {
          authRequired: true
        },
        component: Checking
      },
      {
        path: '/telemed',
        name: 'TELE',
        meta: {
          authRequired: true
        },
        component: Telemed
      },
      {
        path: '/support',
        name: 'SUPPORT',
        meta: {
          authRequired: true
        },
        component: Support
      },
      // {
      //   path: '/examination',
      //   name: 'EXAMINATION',
      //   meta: {
      //     authRequired: false
      //   },
      //   component: () => import(/* webpackChunkName: "forgetpassword" */'../views/Examination.vue')
      // },
      {
        path: '/administrator',
        name: 'ADMIN',
        meta: {
          authRequired: true
        },
        component: Administrator
      },
      {
        path: '/forgetpassword',
        name: 'ADMIN',
        meta: {
          authRequired: false
        },
        component: () => import(/* webpackChunkName: "forgetpassword" */'../views/ForgetPassword.vue')
      }
    ]
  },
  {
    path: '*',
    meta: {
      authRequired: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

registerGuard(router)

export default router
