import { GetGroupInfo } from '@/api/'

const state = () => ({
  user: {},
  group: {},
  group_list: [],
  report_list: [],
  groupLoading: false,
  pagePermit: [],
  pageList: [
    { title: 'Screening', icon: 'mdi-hospital-building', navigate: 'SCREENING', page: 'SCREENING', order: 1},
    { title: 'Physician', icon: 'mdi-stethoscope', navigate: 'DOCTOR_ROOM', page: 'DOCTOR_ROOM', order: 2 },
    { title: 'Pharmacy', icon: 'mdi-pill', navigate: 'PHARMACY', page: 'PHARMACY', order: 3},
    { title: 'Laboratory', icon: 'mdi-water-outline', navigate: 'LABORATORY', page: 'LABORATORY', order: 4},
    { title: 'Patient', icon: 'mdi-account-details', navigate: 'PATIENT', page: 'PATIENT', order: 5},
    { title: 'Inventory', icon: 'mdi-inbox-multiple', navigate: 'INVENTORY', page: 'INVENTORY', order: 6},
    { title: 'Sets', icon: 'mdi-package-variant-closed', navigate: 'CHECKING', page: 'CHECKING', order: 7},
    { title: 'Shift', icon: 'mdi-account-supervisor', navigate: 'SHIFT', page: 'SHIFT', order: 8},
    { title: 'Report', icon: 'mdi-file-chart', navigate: 'REPORT', page: 'REPORT', order: 9},
    { title: 'Tele-visit', icon: 'mdi-video', navigate: 'TELE', page: 'TELE', order: 10},
    { title: 'My Profile', icon: 'mdi-account-box', navigate: 'PROFILE', page: 'PROFILE', order: 11 },
    { title: 'Company Settings', icon: 'mdi-cogs', navigate: 'SETTING', page: 'SETTING', order: 12},
    { title: 'Help + Support', icon: 'mdi-face-agent', navigate: 'SUPPORT', page: 'SUPPORT', order: 13},
    { title: 'Administrator', icon: 'mdi-cancel', navigate: 'ADMIN', page: 'ADMIN', order: 14}
  ],
  landingPage: {},
  navItems: [],
  firstTime: true,
  canBook: '',
  currentGroupStock: {}
})

const mutations = {
  SET_USER (state, data) {
    state.user = data
  },
  SET_FIRST_TIME (state, data) {
    state.firstTime = data
  },
  SET_CAN_BOOK (state, data) {
    state.canBook = '' 
    if (data) {
      for (let i = 0; i < data.length; i++) {
        state.canBook += data[i].user_group + '|'
      }
    }
  },
  SET_CURRENT_GROUP_STOCK (state, data) {
    state.currentGroupStock = data
  },
  SET_GROUP_LOADING (state, data) {
    state.groupLoading = data
  },
  SET_GROUP_LIST (state, data) {
    try {
      const groupList = []
      data.forEach(element => {
        groupList.push(JSON.parse(element))
      })
      state.group_list = groupList
    } catch {
      state.group_list = []
    }
  },
  SET_REPORT_LIST (state, data) {
    try {
      const reportList = []
      data.forEach(element => {
        reportList.push(JSON.parse(element))
      })
      state.report_list = reportList
    } catch {
      state.report_list = []
    }
  },
  SET_GROUP (state, data) {
    state.group = data
  },
  SET_NAVIGATION_ITEMS (state, landingPage) {
    state.navItems = []
    state.pageList.forEach((page) => {
      for (let i = 0; i < state.user.roles.length; i++) {
        if (page.page === state.user.roles[i]) {
          state.navItems.push(page)
          break
        }
      }
      if (page.page === landingPage) {
        state.landingPage = page
      }
    })
  },
  SET_PAGE_PERMIT (state, data) {
    state.pagePermit = data.split('|').filter(i => i)
  },
  SET_USER_AVATAR (state, data) {
    state.user.img_user = data
  }
}

const actions = {
  CHECK_PAGE_PERMIT ({ commit, state }, to) {
    for (let i = 0; i < state.navItems.length; i++) {
      if (state.navItems[i].navigate.includes(to.name)) {
        return true
      }
    }
    return false
  },
  SET_FIRST_TIME ({ commit }, value) {
    commit('SET_FIRST_TIME', value)
  },
  GET_DEFAULT_PAGE  ({ commit, state }) {
    return state.navItems[0]
  },
  GET_GROUP ({ commit }, groupId) {
    return new Promise((resolve, reject) => {
      GetGroupInfo({ groupId: groupId }, message => {
        if (message.data) {
          commit('SET_GROUP', message.data[0])
          commit('SET_CAN_BOOK', message.data[0].user_can_book)
          commit('SET_CURRENT_GROUP_STOCK', message.data[0].group_stocks[0])
          resolve(message.data)
        } else {
          reject(new Error('not found'))
        }
      }, error => {
        reject(error)
      })
    })
  },
  SET_USER ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_GROUP_LOADING', true)
      commit('SET_USER', data)
      commit('SET_GROUP_LIST', data.group_list)
      commit('SET_REPORT_LIST', data.report_list)
      commit('SET_NAVIGATION_ITEMS', data.landing_page)
      dispatch('User/GET_GROUP', (state.group.group_id) ? state.group.group_id : data.group_id, { root: true }).then((message) => {
        commit('SET_GROUP_LOADING', false)
        resolve(message)
      }).catch((error) => {
        commit('SET_GROUP_LOADING', false)
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
