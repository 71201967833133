import { GetShiftsInfoByDate }  from '@/api'
import { getUserGroup } from '@/api/func_app_api'
// import router from '../../router'
const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const groupByDes = groupBy('user_group_thai')
const groupByDoctorType = groupBy('user_group')

const getDefaultState = () => {
  return {
    shiftList: { data: null, loading: false, status: false },
    shiftGroup: { data: {}, type: null },
    userGroup: []
  }
}
const state = getDefaultState()

const mutations = {
  SET_USER_GROUP(state, data) {
    state.userGroup = data
  },
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setShiftList (state, payload) {
    state.shiftList.data = payload
  },
  setShiftListLoading (state, payload) {
    state.shiftList.loading = payload
  },
  setShiftListStatus (state, payload) {
    state.shiftList.status = payload
  },
  setShiftGroup (state, payload) {
    state.shiftGroup.data = groupByDes(payload)
    let group = Object.keys(groupByDoctorType(payload))
    let str = ''
    for (let i = 0; i < group.length; i++) {
      str += group[i] + '|'
    }
    state.shiftGroup.type = str
  }
}

const actions = {
  GET_USER_GROUP ({commit}, user_group) {
    return new Promise((resolve, reject) => {
      getUserGroup({user_group: user_group},message=>{
        if (message.data.result) {
          commit('SET_USER_GROUP', message.data.result)
          resolve(message.data.result)
        } else {
          resolve([])
        }
      }, error=> {
        reject(error)
      })
    })
  },
  GetShiftsInfoByDate ({ commit, rootState }, { date, grouplist }) {
    commit('setShiftListLoading', true)
    commit('setShiftListStatus', null)
    GetShiftsInfoByDate({ date: date, grouplist: grouplist, groupid: rootState.User.group.group_id }, message => {
      if (message.data == null) {
        commit('setShiftListStatus', false)
        commit('setShiftList', [])
        commit('setShiftGroup', [])
      } else {
        commit('setShiftListStatus', true)
        commit('setShiftList', message.data)
        commit('setShiftGroup', message.data)
      }
      commit('setShiftListLoading', false)
    }, error => {
      commit('setShiftListLoading', false)
      console.log(error)
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
