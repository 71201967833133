import * as api from '../../api'
// import * as azure_api from '../../api/func_app_api'
// import router from '../../router'

const getDefaultState = () => {
  return {
    bagData: { data: [], status: false, loading: false },
    bagItemData: { data: [], status: false, loading: false },
    bagItemResult: { code: null, message: null, status: false, loading: false },
    bagItemCheckingHistory: { header: [], item: [], data: [], status: false, loading: false },
    departmentCodeData: { data: [], status: false, loading: false },
    boxOrderResult: { code: null, message: null, status: false, loading: false }
  }
}

const state = getDefaultState()
const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setBagData (state, data) {
    if (data === null) { state.bagData.data = [] } else {
      let arrTempData = data
      arrTempData.forEach(tempData => {
        tempData.display_exp_date = new Date(tempData.exp_date).toLocaleDateString('th-th')
        ' ' +
          new Date(tempData.exp_date).toLocaleTimeString('en-US', {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric'
          })
      })
      state.bagData.data = arrTempData
    }
  },
  setBagDataLoading (state, data) {
    state.bagData.loading = data
  },
  setBagDataStatus (state, data) {
    state.bagData.status = data
  },
  setBagItemData (state, data) {
    if (data === null) { state.bagItemData.data = [] } else {
      var itemList = []
      for (var i = 0; i < data.items_list.length; i++) {
        var item = data.items_list[i]
        if (data.items_check != null && data.items_check != '') {
          for (var j = 0; j < data.items_check[0].check_result.length; j++) {
            // var row = data.items_check[0].check_result[i];
            var row = data.items_check[0].check_result[j]
            if (row.set_order == item.set_order) {
              item.remain = row.remain
              item.suggest_order = item.quantity - item.remain
              if (item.suggest_order < 0) {
                item.suggest_order = 0
              }
              item.current_checking = ''
              break
            }
          }
        } else {
          item.remain = 0
          item.suggest_order = item.quantity - item.remain
          item.current_checking = ''
        }
        itemList.push(item)
      }
      data.tableItem = itemList
      state.bagItemData.data = data
    }
  },
  setBagItemDataLoading (state, data) {
    state.bagItemData.loading = data
  },
  setBagItemDataStatus (state, data) {
    state.bagItemData.status = data
  },
  setBagItemResult (state, data) {
    state.bagItemResult.code = data.code
    state.bagItemResult.message = data.message
  },
  setBagItemResultLoading (state, data) {
    state.bagItemResult.loading = data
  },
  setBagItemResultStatus (state, data) {
    state.bagItemResult.status = data
  },
  setBagItemCheckingHistory (state, data) {
    if (data == null) {
      state.bagItemCheckingHistory.header = []
      state.bagItemCheckingHistory.item = []
      state.bagItemCheckingHistory.data = []
    } else {
      var header = []
      header.push({ text: 'รหัส', value: 'Matcode', sortable: false })
      header.push({ text: 'ชื่อรายการ', value: 'Generic_name', sortable: false })
      header.push({ text: 'จำนวนเต็ม', value: 'quantity', sortable: false })
      header.push({ text: 'หน่วย', value: 'BUN_label', sortable: false })
      if (data.items_check != null) {
        for (var i = 0; i < data.items_check.length; i++) {
          var _chkDate = new Date(data.items_check[i].check_dt).toLocaleDateString('th-th') // +
          // ' ' +
          // new Date(data.items_check[i].check_dt).toLocaleTimeString('en-US', {
          //   hour12: false,
          //   hour: 'numeric',
          //   minute: 'numeric'
          // })
          header.push({ text: 'ตรวจนับวันที่ ' + _chkDate, value: 'd' + i, date: data.items_check[i].check_dt, remark: data.items_check[i].check_comment, check_id: data.items_check[i].check_id, sortable: false })
        }
      } else {
        header.push({ text: 'ไม่มีประวัติการตรวจนับ', value: 'no_history' })
      }

      var itemList = []
      for (var i = 0; i < data.items_list.length; i++) {
        var item = {}
        item = data.items_list[i]
        if (data.items_check != null && data.items_check != '') {
          for (var k = 0; k < data.items_check.length; k++) {
            for (var j = 0; j < data.items_check[k].check_result.length; j++) {
              var row = data.items_check[k].check_result[j]
              if (k == 0) {
                if (row.set_order == item.set_order) {
                  item.d0 = row.remain
                  break
                }
              } else if (k == 1) {
                if (row.set_order == item.set_order) {
                  item.d1 = row.remain
                  break
                }
              } else if (k == 2) {
                if (row.set_order == item.set_order) {
                  item.d2 = row.remain
                  break
                }
              }
            }
          }
          itemList.push(item)
        } else {
          item.d0 = 0
          item.d1 = 0
          item.d2 = 0
          itemList.push(item)
        }
      }

      // // add remark
      // if (data.items_check != null && data.items_check != '' && data.items_check.length > 0) {
      //   let itemRemark = { Matcode: "หมายเหตุ" }
      //   data.items_check.length > 0 ? itemRemark.d0 = data.items_check[0].check_comment : itemRemark.d0 = "";
      //   data.items_check.length > 1 ? itemRemark.d1 = data.items_check[1].check_comment : itemRemark.d1 = "";
      //   data.items_check.length > 2 ? itemRemark.d2 = data.items_check[2].check_comment : itemRemark.d2 = "";
      //   itemList.push(itemRemark)
      // }

      // // add checking user
      // if (data.items_check != null && data.items_check != '' && data.items_check.length > 0) {
      //   let itemUser = { Matcode: "ผู้ตรวจนับ" }
      //   data.items_check.length > 0 ? itemUser.d0 = data.items_check[0].user_fullname : itemUser.d0 = "";
      //   data.items_check.length > 1 ? itemUser.d1 = data.items_check[1].user_fullname : itemUser.d1 = "";
      //   data.items_check.length > 2 ? itemUser.d2 = data.items_check[2].user_fullname : itemUser.d2 = "";
      //   itemList.push(itemUser)
      // }

      state.bagItemCheckingHistory.header = header
      state.bagItemCheckingHistory.item = itemList
      state.bagItemCheckingHistory.data = data
    }
  },
  setBagItemCheckingHistoryLoading (state, data) {
    state.bagItemCheckingHistory.loading = data
  },
  setBagItemCheckingHistoryStatus (state, data) {
    state.bagItemCheckingHistory.status = data
  },
  setDepartmentCodeData (state, data) {
    if (data == null) {
      state.departmentCodeData.data = []
    } else {
      state.departmentCodeData.data = data
    }
  },
  setDepartmentCodeDataLoading (state, data) {
    state.departmentCodeData.loading = data
  },
  setDepartmentCodeDataStatus (state, data) {
    state.departmentCodeData.status = data
  },
  setBoxOrderResult (state, data) {
    state.boxOrderResult.code = data.code
    state.boxOrderResult.message = data.message
  },
  setBoxOrderResultLoading (state, data) {
    state.boxOrderResult.loading = data
  },
  setBoxOrderResultStatus (state, data) {
    state.boxOrderResult.status = data
  }
}
const actions = {
  getBagData ({ rootState, commit }, payload) {
    commit('setBagDataLoading', true)
    commit('setBagDataStatus', false)
    api.GetBag({ groupId: payload.groupId, setId: payload.setId, checkStart: payload.checkStart, userId: payload.userId, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setBagData', null)
      } else {
        commit('setBagData', message.data)
      }
      commit('setBagDataLoading', false)
      commit('setBagDataStatus', true)
    }, error => {
      commit('setBagDataLoading', false)
      commit('setBagDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  initialBagItemData ({ commit }) {
    commit('setBagItemData', null)
  },
  getBagItemData ({ rootState, commit }, payload) {
    commit('setBagItemDataLoading', true)
    commit('setBagItemDataStatus', false)
    api.GetBag({ groupId: payload.groupId, setId: payload.setId, checkStart: payload.checkStart, userId: payload.userId, token: rootState.authen.token }, message => {
      if (message.data !== null) {
        commit('setBagItemData', message.data[0])
      }
      commit('setBagItemDataLoading', false)
      commit('setBagItemDataStatus', true)
    }, error => {
      commit('setBagItemDataLoading', false)
      commit('setBagItemDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  postBagItemData ({ rootState, commit }, payload) {
    commit('setBagItemResultLoading', true)
    commit('setBagItemResultStatus', false)
    api.PostBag({ body: payload, token: rootState.authen.token }, message => {
      if (message.data !== null) {
        commit('setBagItemResult', message.data)
      }
      commit('setBagItemResultLoading', false)
      commit('setBagItemResultStatus', true)
      // azure_api.SaveLog({ data: { user_id: payload.user_id, name: 'CMS', api_name: process.env.VUE_APP_API_BASE_URL + '/bag', method: 'POST', request: JSON.stringify(payload), response: JSON.stringify(message.data) } }, message => { console.log('save log success') }, error => { console.log('save log fail') })
    }, error => {
      commit('setBagItemResultLoading', false)
      commit('setBagItemResultStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  getBagItemDataChecking ({ rootState, commit }, payload) {
    // commit('resetstate')
    commit('setBagItemCheckingHistoryLoading', true)
    commit('setBagItemCheckingHistoryStatus', false)
    api.GetBag({ groupId: payload.groupId, setId: payload.setId, checkStart: payload.checkStart, userId: payload.userId, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setBagItemCheckingHistory', null)
      } else {
        commit('setBagItemCheckingHistory', message.data[0])
      }
      commit('setBagItemCheckingHistoryLoading', false)
      commit('setBagItemCheckingHistoryStatus', true)
    }, error => {
      commit('setBagItemCheckingHistoryLoading', false)
      commit('setBagItemCheckingHistoryStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  getDepartmentCodeData ({ rootState, commit }, payload) {
    commit('setDepartmentCodeDataLoading', true)
    commit('setDepartmentCodeDataStatus', false)
    api.GetDepartmentCode({ groupId: payload, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setDepartmentCodeData', null)
      } else {
        commit('setDepartmentCodeData', message.data)
      }
      commit('setDepartmentCodeDataLoading', false)
      commit('setDepartmentCodeDataStatus', true)
    }, error => {
      commit('setDepartmentCodeDataLoading', false)
      commit('setDepartmentCodeDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  postBoxOrder ({ rootState, commit }, payload) {
    commit('setBoxOrderResultLoading', true)
    commit('setBoxOrderResultStatus', false)
    api.PostBoxOrder({ body: payload, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setBoxOrderResult', null)
      } else {
        commit('setBoxOrderResult', message.data)
      }
      commit('setBoxOrderResultLoading', false)
      commit('setBoxOrderResultStatus', true)
    }, error => {
      commit('setBoxOrderResultLoading', false)
      commit('setBoxOrderResultStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
