import Vue from 'vue'
import store from '../store'
import { msalInstance, b2cPolicies, apiConfig } from '../authConfig'
import { InteractionType } from '@azure/msal-browser'

const DEFAULT_TITLE = 'ระบบบริหารจัดการสถานพยาบาล | VWELL'

export function registerGuard (router) {
  router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title || DEFAULT_TITLE
    if (to.meta.authRequired) {
      const request = {
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        redirectUri: window.location.origin,
        scopes: [...apiConfig.b2cScopes],
        redirectStartPage: to.fullPath,
        extraQueryParameters: { ui_locales: 'th' }
      }
      const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request)
      if (shouldProceed) {
        if (from.path === '/' && to.path === '/') {
          next({ name: store.state.User.landingPage.navigate })
        } else {
          const token = await Vue.prototype.$auth.acquireToken()
          if (token.idTokenClaims.roles.filter(el=> el === to.name).length > 0) {
            next()
          } else {
            next({ name: token.idTokenClaims.roles[0] })
          }
        }
      } else {
        Vue.prototype.$auth.logout()
      }
      // Implement routing to select site or tenant
    } else {
      next()
    }
    // next()
  })
}

export async function isAuthenticated (instance, interactionType, loginRequest) {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance.handleRedirectPromise().then(async (response) => {
    if (response !== null) {
      // if (!(response.idTokenClaims.user_info && response.idTokenClaims.user_info.length > 0)) return false
      return true
    } else {
      const accounts = instance.getAllAccounts()
      if (accounts.length > 0) {
        // if (!(response.idTokenClaims.user_info && response.idTokenClaims.user_info.length > 0)) return false
        return true
      }
    }
    if (interactionType === InteractionType.Redirect) {
      return instance.loginRedirect(loginRequest).then(() => {
        return true
      }).catch(() => {
        // return false
      })
    }
    return false
  }).catch((error) => {
    if (error.errorMessage) {
      if (error.errorMessage.indexOf('AADB2C90118') > -1) {
        instance.loginRedirect({
          authority: b2cPolicies.authorities.forgotPassword.authority,
          scopes: [...apiConfig.b2cScopes],
          extraQueryParameters: { ui_locales: 'th' }
        })
      } else {
        console.trace()
        instance.loginRedirect({
          authority: b2cPolicies.authorities.signUpSignIn.authority,
          scopes: [...apiConfig.b2cScopes],
          extraQueryParameters: { ui_locales: 'th' }
        })
      }
    }
  })
}

export async function checkPagePermit (to) {
  const canAccess = await store.dispatch('User/CHECK_PAGE_PERMIT', to)
  return canAccess
}