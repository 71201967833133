<template>
    <v-app-bar
      app
      fixed
      elevate-on-scroll
      style="border-top: 3px solid #2F2F88;background-color: #FFFFFF"
    >
      <v-app-bar-nav-icon color="primary" @click="$emit('openDrawer')"></v-app-bar-nav-icon>
       <v-toolbar-title>
        <v-img
          v-if="!$vuetify.breakpoint.xsOnly"
          width="110"
          contain
          :src="require('@/assets/logo-text.png')"
        ></v-img>
       </v-toolbar-title>
       <div class="ml-4 text-subtitle-1" v-if="mode!=='production'"> Demonstration Purposes Only</div>
      <v-spacer />
      <v-spacer></v-spacer>
      <v-card v-if="!$vuetify.breakpoint.xsOnly" color="grey lighten-4" flat @click="tenantDialog = true">
        <v-card-text class="primary--text text--darken-4 text-body-1 text-right">
        <b>สถานที่ปฏิบัติงาน : </b> {{group.group_describe}} <br>
        </v-card-text>
      </v-card>
      <!-- <v-menu
        left
        offset-y
        allow-overflow
        v-if="!$vuetify.breakpoint.xsOnly"
      >
       <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
          <v-btn icon tile v-on="{ ...tooltip, ...menu }" @click="alert=true">
            <v-badge
        :content="noOfRestock"
        :value="noOfRestock"
        color="red"
      >
            <v-icon color="black">mdi-alert</v-icon>
            </v-badge>
          </v-btn>
          </template>
           <span>แจ้งเตือนรายการยา {{noOfRestock}}</span>
          </v-tooltip>
        </template>
      </v-menu> -->
      <v-menu
        left
        offset-y
        max-width="400"
      >
        <template v-slot:activator="{ on: menu2 }">
           <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip2 }">
          <v-btn tile v-on="{ ...tooltip2, ...menu2 }" elevation="0" text>
            <v-icon color="black" >mdi-account-circle-outline</v-icon>
      {{user.user_fullname}} <br> {{user.user_group_thai}}
          </v-btn>
          </template>
           <span>ข้อมูลของท่าน</span>
           </v-tooltip>
        </template>

        <v-list>
          <v-list-item @click="navigate('PROFILE')">
             <v-list-item-avatar :size="80" class="ma-2">
              <v-img contain :src="pat_img || require('@/assets/avatar.png')"/>
            </v-list-item-avatar>
             <v-list-item-content>
            <v-list-item-title>{{user.user_fullname}}</v-list-item-title>
            <v-list-item-subtitle>{{user.user_group_thai}}</v-list-item-subtitle>
             <v-list-item-subtitle>{{user.dept}}</v-list-item-subtitle>
             <v-list-item-subtitle class="text-wrap">สถานที่ปฏิบัติงาน {{group.group_describe}}</v-list-item-subtitle>
              <v-divider></v-divider>
             <!-- <v-list-item-subtitle><a @click="changePassword()">เปลี่ยนรหัสผ่าน</a></v-list-item-subtitle> -->
             </v-list-item-content>
          </v-list-item>
              <v-list-item><v-list-item-content> <v-btn color="primary" outlined block elevation="0" @click="tenantDialog = true">เปลี่ยนสถานที่ปฏิบัติงาน</v-btn></v-list-item-content></v-list-item>
              <v-list-item><v-list-item-content>
             <v-list-item-subtitle><v-icon small>mdi-file-outline</v-icon> <a @click="pdpaDialog(0)" >ข้อกำหนดและเงื่อนไขการใช้งานแอปพลิเคชั่น</a></v-list-item-subtitle>
             <v-list-item-subtitle><v-icon small>mdi-account-check-outline</v-icon><a @click="pdpaDialog(1)">นโยบายความเป็นส่วนตัวสำหรับลูกค้า</a></v-list-item-subtitle>
             <v-list-item-subtitle><v-icon small>mdi-cookie</v-icon><a @click="pdpaDialog(2)">นโยบายการใช้คุกกี้</a></v-list-item-subtitle>
             <v-list-item-subtitle><v-icon small>mdi-key</v-icon><a @click="changePassword()">เปลี่ยนรหัสผ่าน</a></v-list-item-subtitle>
             <v-list-item-subtitle><small>Build version {{version}}</small></v-list-item-subtitle>
            </v-list-item-content></v-list-item>
        </v-list>
          <v-divider></v-divider>
      </v-menu>
      <v-divider vertical class="mx-2"/>
      <v-tooltip bottom>
          <template v-slot:activator="{ on }">
       <v-btn icon tile @click="logout">
            <v-icon color="black" v-on="on" >mdi-logout-variant</v-icon>
      </v-btn>
          </template>
          <span>ลงชื่อออก</span>
      </v-tooltip>
      <v-dialog v-model="cookieDialog" width="600" persistent scrollable>
        <Cookie @onClose="cookieDialog = false" :type="type" :key="cookieDialog"/>
      </v-dialog>
      <v-dialog v-model="alert" max-width="600" scrollable>
        <AlertCard @onClose="alert = false" :items="restockItem"/>
     </v-dialog>
<v-dialog v-model="tenantDialog" max-width="400" persistent>
      <SelectTenantCard @onClose="tenantDialog= false"/>
    </v-dialog>
    </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import { GetRestock } from '@/api/'
import SelectTenantCard from './SelectTenantCard'
import AlertCard from './AlertCard'
import { GetConsent } from '@/api/func_app_api'
export default {
  components: {
    SelectTenantCard,
    AlertCard,
    Cookie: () => import('@/components/gdpr/Cookie')
  },
  data () {
    return {
      mode: process.env.VUE_APP_MODE,
      version: process.env.VUE_APP_VERSION,
      tenantDialog: false,
      pat_img: null,
      cookieDialog: false,
      alert: false,
      restockItem: [],
      type: 0
    }
  },
  watch: {
    group : {
      handler() {
        this.getRestock()
      },
      deep: true
    },
    user: {
      handler() {
        this.getImg()
      },
      deep: true
    }
    },
  computed: {
    ...mapState('User', ['user', 'group', 'firstTime']),
    noOfRestock () {
      return this.restockItem.length
    }
  },
  mounted () {
    if (this.firstTime) {
      this.tenantDialog = true
    }
    this.getImg()
    this.getRestock()
    GetConsent(message => {
      if (message.data.result) {
        if (message.data.result.length > 0) {
          const consent = message.data.result.filter(element => element.cookies_type === 'Analytic')
          if (consent.length > 0) {
            if (consent[0].accept === 'Y') {
              console.log('Enable GA')
              this.$ga.enable()
              this.$ga.set('userId', this.$auth.accounts[0].localAccountId);
            } else {
              this.$ga.disable()
            }
          } else {
            this.consent = true
          }
        } else {
          this.consent = true
          this.$ga.disable()
        }
      }
    }, error => { console.log(error) })
  },
  methods: {
    onAlertClose () {
      this.alert = false
    },
    logout () {
      this.$auth.logout()
      sessionStorage.clear()
    },
    pdpaDialog (type) {
      this.type = type
      this.cookieDialog = true
    },
    getRestock () {
      GetRestock({ groupId: this.group.group_id, groupStock: 0, matGroup: '201', active: 'O', matDesc: '%' }, message => {
        if (message.data) {
          this.restockItem = message.data
        } else {
          this.restockItem = []
        }
      }, error => { console.log(error) })
    },
    getImg () {
      if (this.user.img_user) {
        this.$store.dispatch('File/GET_USER_AVATAR', this.user.img_user).then((url)=> {
          this.pat_img = url
        }).catch(()=>{
          this.pat_img = null
        })
      }
    },
    navigate (name) {
      this.$router.push({ name: name })
    },
    changePassword() {
      this.$auth.changePassword()
    }
  }
}
</script>

<style>

</style>
