
const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'x-functions-key': process.env.VUE_APP_BLOB_KEY
}

const URL = process.env.VUE_APP_BLOB_URL
// Implement
export function PostLabFile ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + 'UploadLabFile',
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getFile (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + `file?blob_name=${param.blob_name}&blob_type=${param.blob_type}&group_id=${param.group_id}`,
    headers: headers,
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postFile (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + `file`,
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getUserGroup ( param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + `user-group?user_group=${param.user_group}`,
    headers: headers,
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}
export function UploadOtherMedicalFile ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + 'UploadOtherMedicalFile',
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetVisitDocument (visitId, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + `visit-document?visit_id=${visitId}`,
    headers: headers
    }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function DeleteVisitDocument (data, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: URL + 'visit-document',
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetUserGroup (cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + 'GetUserGroup',
    headers: headers
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function UploadStampImage ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + 'UploadStampImage',
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function UploadStampShareFile ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + 'UploadStampShareFile',
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetConsent (cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + 'consent',
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostConsent ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + 'consent',
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PutConsent ({ data }, cb, errorCb) {
  window.axios({
    method: 'put',
    url: URL + 'consent',
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetSiteChatThread (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + 'chat-thread-site?group_id=' + param.groupId + '&rid= ' + param.rid + '&patient_id=' + param.patientId,
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetVisitChatThread (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + 'chat-thread-site?group_id=' + param.groupId + '&rid= ' + param.rid + '&patient_id=' + param.patientId,
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostVisitChatThread (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + 'chat-thread-visit',
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getSitesPromise (param) {
  return window.axios({
    method: 'get',
    url: URL + 'communication-service?group_id=' + param.groupId,
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  })
}

export function patchSitePromise (param) {
  return window.axios({
    method: 'patch',
    url: URL + 'communication-service?group_id=' + param.groupId + '&id=' + param.id + '&_etag=' + param.etag,
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  })
}

export function inviteUser (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + 'invite-user',
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getCommunicationUser () {
  return window.axios({
    method: 'post',
    url: URL + 'communication-user',
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  })
}

export function getStockItemsTransac (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + 'item-stock-transac?stock_id=' + param.stock_id,
    headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postTeleInvitation (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + 'tele-invitation',
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function getUsers (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + `user-acc?page_size=${param.page_size}&page_no=${param.page_no}&keyword=${param.keyword}&uuid=${param.uuid}`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function getUserAD (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + `ad-username?uuid=${param.uuid}`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function changePassword(data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + `ad-password`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function createUser (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + `user-acc`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function updateUsername (data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: URL + `ad-username`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}


export function updateUser (data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: URL + `user-acc`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function getItemMaster (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + `item_master?page_size=${param.page_size}&page_no=${param.page_no}&keyword=${param.keyword}`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function postItemMaster (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + `item_master`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function patchItemMaster (data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: URL + `item_master`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY },
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function getDrugGroup (cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + `drug_group`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function getRefMatgroup (cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + `ref_matgroup`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}

export function getItemBun (cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + `item_bun`,
    headers: { 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-functions-key': process.env.VUE_APP_BLOB_KEY }
  }).then(response => {
    cb(response)
  }).catch(error => errorCb(error))
}