import * as api from '../../api'
import router from '../../router'
const getDefaultState = () => {
  return {
    orderList: { data: [], loading: false, status: null, post: null, message: '' },
    applyList: { data: [], loading: false }
  }
}
const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setOrderList (state, data) {
    state.orderList.data = data
  },
  setOrderLoading (state, data) {
    state.orderList.loading = data
  },
  setOrderStatus (state, data) {
    state.orderList.status = data
  },
  setOrderPost (state, data) {
    state.orderList.post = data
  },
  setOrderMessage (state, data) {
    state.orderList.message = data
  },
  setApplyList (state, data) {
    state.applyList.data = data
  },
  setApplyListLoading (state, data) {
    state.applyList.loading = data
  }
}

const actions = {
  GetApplyByKeyword ({ rootState, commit }, keyword) {
    commit('setApplyListLoading', true)
    api.GetApply({ keyword: keyword }, message => {
      if (message.data == null) {
        commit('setApplyList', [])
      } else {
        commit('setApplyList', message.data)
      }
      commit('setApplyListLoading', false)
    }, error => {
      commit('setApplyListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetOrderByVisit ({ rootState, commit }, visitId) {
    commit('setOrderLoading', true)
    commit('setOrderStatus', null)
    api.GetOrderByVisit({ visitId: visitId, token: rootState.authen.token }, message => {
      // console.log(message.data)
      if (message.data == null) {
        commit('setOrderStatus', false)
        commit('setOrderList', [])
      } else {
        commit('setOrderStatus', true)
        commit('setOrderList', message.data)
      }
      commit('setOrderLoading', false)
    }, error => {
      commit('setOrderLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  PostOrder ({ commit }, order) {
    commit('setOrderLoading', true)
    commit('setOrderPost', null)
    api.PostOrder({ order: order }, message => {
      if (message.data === null) {
        commit('setOrderList', [])
      } else {
        if (message.data.code === 1) {
          api.GetOrderByVisit({ visitId: order[0].visit_id }, message => {
            if (message.data == null) {
              commit('setOrderStatus', false)
              commit('setOrderList', [])
            } else {
              commit('setOrderStatus', true)
              commit('setOrderList', message.data)
            }
            commit('setOrderLoading', false)
          }, error => {
            commit('setOrderLoading', false)
            console.log(error)
          })
        } else {
          commit('setOrderPost', false)
          commit('setOrderLoading', false)
          commit('setOrderMessage', message.data.message)
        }
        // console.log(message.data)
      }
    }, error => {
      commit('setOrderLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
