import * as api from '../../api'

import { getField, updateField } from 'vuex-map-fields'

function onlyUnique (value, index, self) {
  return self.indexOf(value) === index
}

const getDefaultState = () => {
  return {
    diagList: { data: [], loading: false, status: null, post: null },
    diagInfo: { data: [], loading: false, status: null, post: null },
    diagView: { data: [], loading: false, status: null },
    hx: '',
    pe: '',
    note: '',
    diaglist: [],
    diagCopy: [],
    diagCopyStatus: false
  }
}
const state = getDefaultState()

const mutations = {
  updateField,
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setdiagCopyStatus (state, data) {
    state.diagCopyStatus = data
  },
  setDiag (state, data) {
    if (data) {
      if (Array.isArray(data) && data.length > 0) {
        if (data[0].hasOwnProperty('document')) {
          state.diaglist = [...state.diaglist, ...data].filter(onlyUnique)
        } else {
          state.diaglist = [...state.diaglist, ...data.map(function (val) {
            return { document: val, text: val.description }
          })].filter(onlyUnique)
        }
      }
    } else {
      state.diaglist = []
    }
  },
  removeDiag (state, data) {
    let obj = state.diaglist.filter(item => item.document.code_diag === data.document.code_diag)
    const index = state.diaglist.indexOf(obj[0])
    if (index >= 0) state.diaglist.splice(index, 1)
  },
  setDiagCopy (state, data) {
    if (data) {
      if (Array.isArray(data) && data.length > 0) {
        if (data[0].hasOwnProperty('document')) {
          state.diaglist = [...state.diaglist, ...data].filter(onlyUnique)
        } else {
          state.diaglist = [...state.diaglist, ...data.map(function (val) {
            return { document: val, text: val.description }
          })].filter(onlyUnique)
        }
      }
    } else {
      state.diaglist = []
    }
  },
  setDiagView (state, data) {
    if (data) {
      if (Array.isArray(data) && data.length > 0) {
        if (data[0].hasOwnProperty('document')) {
          state.diagView.data = data.map(function (val) {
            return val.document
          })
        } else {
          state.diagView.data = data
        }
      }
    } else {
      state.diagView.data = []
    }

    // state.diagView.data = data
  },
  setDiagViewLoading (state, data) {
    state.diagView.loading = data
  },
  setDiagViewStatus (state, data) {
    state.diagView.status = data
  },
  setDiagList (state, data) {
    state.diagList.data = data
  },
  setDiagLoading (state, data) {
    state.diagList.loading = data
  },
  setDiagStatus (state, data) {
    state.diagList.status = data
  },
  setDiagPost (state, data) {
    state.diagList.post = data
  },
  setDiagInfo (state, data) {
    state.diagInfo.data = data
  },
  setDiagInfoLoading (state, data) {
    state.diagInfo.loading = data
  },
  setDiagInfoStatus (state, data) {
    state.diagInfo.status = data
  },
  setHx (state, data) {
    state.hx = data
  },
  setPe (state, data) {
    state.pe = data
  },
  setNote (state, data) {
    state.note = data
  }
}

const actions = {
  ResetDiag ({ commit }) {
    commit('resetstate')
  },
  SetDiagListData ({ commit }, data) {
    commit('setDiagList', data)
  },
  SetCopyNote ({ commit }, data) {
    // commit('setCopyNote', data)
  },
  SetDiagCopy ({ commit }, data) {
    commit('setdiagCopyStatus', false)
    commit('setDiagCopy', data)
    commit('setdiagCopyStatus', true)
    // console.log(data)
  },
  SetDiag ({ commit }, data) {
    commit('setDiag', data)
  },
  SetHx ({ commit }, data) {
    commit('setHx', data)
  },
  SetPe ({ commit }, data) {
    commit('setPe', data)
  },
  SetNote ({ commit }, data) {
    commit('setNote', data)
  },
  RemoveDiag ({ commit }, data) {
    commit('removeDiag', data)
  },
  GetDiagFromVisit ({ commit }, visitId) {
    commit('setDiagInfoLoading', true)
    commit('setDiagInfoStatus', null)
    // console.log('get diag from visit' + visitId)
    api.GetDiagFromVisit({ visitId: visitId }, message => {
      // console.log(message.data)
      if (message.data == null) {
        commit('setDiagInfoStatus', false)
        commit('setDiagInfo', [])
        commit('setDiag', [])
      } else {
        commit('setDiagInfoStatus', true)
        commit('setDiagInfo', message.data)
        commit('setDiag', message.data)
      }
      commit('setDiagInfoLoading', false)
    }, error => {
      commit('setDiagInfoLoading', false)
      console.log(error)
    })
  },
  GetDiagViewFromVisit ({ commit }, visitId) {
    commit('setDiagViewLoading', true)
    commit('setDiagViewStatus', null)
    commit('setDiagView', [])
    api.GetDiagFromVisit({ visitId: visitId }, message => {
      // console.log(message.data)
      if (message.data == null) {
        commit('setDiagViewStatus', false)
        commit('setDiagView', [])
      } else {
        commit('setDiagViewStatus', true)
        commit('setDiagView', message.data)
      }
      commit('setDiagViewLoading', false)
    }, error => {
      commit('setDiagViewLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetDiagFromKeyword ({ rootState, commit }, keyword) {
    commit('setDiagLoading', true)
    commit('setDiagStatus', null)
    // console.log('get diag')
    api.GetDiagFromKeyword({ keyword: keyword }, message => {
      // console.log(message.data)
      if (message.data == null) {
        commit('setDiagStatus', false)
        commit('setDiagList', [])
      } else {
        commit('setDiagStatus', true)
        commit('setDiagList', message.data)
      }
      commit('setDiagLoading', false)
    }, error => {
      commit('setDiagLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  PostDiag ({ commit }, diagList) {
    commit('setDiagLoading', true)
    commit('setDiagPost', null)
    api.PostDiag({ diagList: diagList }, message => {
      if (message.data == null) {
        commit('setDiagPost', false)
        commit('setDiagList', [])
      } else {
        if (message.data.code === 1) {
          commit('setDiagPost', true)
        } else {
          commit('setDiagPost', false)
        }
      }
      commit('setDiagLoading', false)
    }, error => {
      commit('setDiagLoading', false)
      console.log(error)
    })
  },
  POST_DIAG ({ commit }, diagList) {
    commit('setDiagLoading', true)
    commit('setDiagPost', null)
    return new Promise((resolve, reject) => {
      api.PostDiag({ diagList: diagList }, message => {
        if (message.data == null) {
          commit('setDiagPost', false)
          commit('setDiagList', [])
        } else {
          if (message.data.code === 1) {
            commit('setDiagPost', true)
          } else {
            commit('setDiagPost', false)
          }
        }
        resolve()
        commit('setDiagLoading', false)
      }, error => {
        commit('setDiagLoading', false)
        console.log(error)
        reject(error)
      })
    })
  }
}

const getters = {
  getField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
