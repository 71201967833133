<template>
  <v-app>
    <AppBar/>
    <router-view />
  </v-app>
</template>

<script>
import AppBar from '@/components/mainlayout/Navbar.vue'
export default {
    components: {
        AppBar,
    },
}
</script>

<style>

</style>