import * as api from '../../api'
// import router from '../../router'

const getDefaultState = () => {
  return {
    PtLabOrderList: { data: [], loading: false },
    PtLabItemList: { data: [], loading: false },
    PtLabOrderPost: { loading: false, status: null, message: '' }
  }
}
const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setPtLabOrderList (state, data) {
    state.PtLabOrderList.data = data
  },
  setPtLabOrderListLoading (state, data) {
    state.PtLabOrderList.loading = data
  },
  setPtLabItemList (state, data) {
    state.PtLabItemList.data = data
  },
  setPtLabItemListLoading (state, data) {
    state.PtLabItemList.loading = data
  },
  setPtLabOrderPostStatus (state, data) {
    state.PtLabOrderPost.status = data
  },
  setPtLabOrderPostLoading (state, data) {
    state.PtLabOrderPost.loading = data
  },
  setPtLabOrderPostMessage (state, data) {
    state.PtLabOrderPost.message = data
  }
}

const actions = {
  ResetLab ({ commit }) {
    commit('resetstate')
  },
  GetLabOrderByRid ({ rootState, commit }, { rid }) {
    commit('setPtLabOrderListLoading', true)
    commit('setPtLabOrderList', [])
    api.GetLabOrderByRid({ rid: rid, status: 'X', groupId: rootState.User.group.group_id }, message => {
      if (message.data !== null) {
        commit('setPtLabOrderList', message.data)
      } else {
      }
      commit('setPtLabOrderListLoading', false)
    }, error => {
      console.log(error)
      commit('setPtLabOrderListLoading', false)
      if (error.response.status === 401) {

      }
    })
  },
  GetLabItemById ({ rootState, commit }, { id, visitId }) {
    commit('setPtLabItemListLoading', true)
    commit('setPtLabItemList', [])
    api.GetLabItemById({ id: id, visitId: visitId }, message => {
      if (message.data !== null) {
        commit('setPtLabItemList', message.data)
      } else {
      }
      commit('setPtLabItemListLoading', false)
    }, error => {
      console.log(error)
      commit('setPtLabItemListLoading', false)
      if (error.response.status === 401) {

      }
    })
  },
  PostLabOrder ({ rootState, commit }, { data }) {
    commit('setPtLabOrderPostLoading', true)
    commit('setPtLabOrderPostMessage', [])
    commit('setPtLabOrderPostStatus', null)
    api.PostLabOrder({ data: data, token: rootState.authen.token }, message => {
      if (message.data !== null) {
        if (message.data.code === 1) {
          commit('setPtLabOrderPostStatus', true)
          commit('setPtLabOrderPostMessage', message.data.message)
        } else {
          commit('setPtLabOrderPostStatus', false)
          commit('setPtLabOrderPostMessage', message.data.message)
        }
      } else {
      }
      commit('setPtLabOrderPostLoading', false)
    }, error => {
      console.log(error)
      commit('setPtLabOrderPostStatus', false)
      commit('setPtLabOrderPostLoading', false)
      if (error.response.status === 401) {

      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
