import * as api from '../../api'
import router from '../../router'

const getDefaultState = () => {
  return {
    labTestData: { data: [], status: false, loading: false },
    labTestResult: { code: null, message: null, status: false, loading: false },
    lisData: { data: [], status: false, loading: false },
    lisResult: { code: null, message: null, status: false, loading: false },
    testData: { data: [], status: false, loading: false },
    labReportData: { data: [], status: false, loading: false },
    labResData: { data: { headers: [], items: [] }, status: false, loading: false }
  }
}

const state = getDefaultState()
const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setLabTestData (state, data) {
    if (data === null) {
      state.labTestData.data = []
    } else {
      let arrTempData = data
      arrTempData.forEach(tempData => {
        let _last_update =
          new Date(tempData.last_update).toLocaleDateString('th-th') +
          ' ' +
          new Date(tempData.last_update).toLocaleTimeString('en-US', {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric'
          })
        tempData.display_last_update = _last_update

        if (tempData.test_status === 'R') {
          tempData.test_status_name = 'รอรับสิ่งตรวจ'
        } else if (tempData.test_status === 'O') {
          tempData.test_status_name = 'รับและส่งไปแล้ว'
        } else if (tempData.test_status === 'G') {
          tempData.test_status_name = 'ส่งมอบห้องแล็บแล้ว'
        } else if (tempData.test_status === 'E') {
          tempData.test_status_name = 'ผลออกแล้ว'
        } else if (tempData.test_status === 'L') {
          tempData.test_status_name = 'ราบงานแล้วห้ามแก้ไข'
        } else if (tempData.test_status === 'C') {
          tempData.test_status_name = 'ยกเลิกแล้ว'
        }
        tempData.lab_res.forEach(lab_res => {
          lab_res.res_datetime =
            new Date(lab_res.res_datetime).toLocaleDateString('th-th') +
            ' ' +
            new Date(lab_res.res_datetime).toLocaleTimeString('en-US', {
              hour12: false,
              hour: 'numeric',
              minute: 'numeric'
            })
        })
      })
      state.labTestData.data = arrTempData
    }
  },
  setLabTestDataLoading (state, data) {
    state.labTestData.loading = data
  },
  setLabTestDataStatus (state, data) {
    state.labTestData.status = data
  },
  setLabTestResult (state, data) {
    state.labTestResult.code = data.code
    state.labTestResult.message = data.message
  },
  setLabTestResultLoading (state, data) {
    state.labTestResult.loading = data
  },
  setLabTestResultStatus (state, data) {
    state.labTestResult.status = data
  },
  setLisData (state, data) {
    if (data === null) {
      state.lisData.data = []
    } else {
      let arrTempData = data
      arrTempData.forEach(tempData => {
        let _last_update =
          new Date(tempData.last_update).toLocaleDateString('th-th') +
          ' ' +
          new Date(tempData.last_update).toLocaleTimeString('en-US', {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric'
          })
        tempData.last_update = _last_update

        if (tempData.test_status === 'R') {
          tempData.test_status_name = 'รอรับสิ่งตรวจ'
        } else if (tempData.test_status === 'O') {
          tempData.test_status_name = 'รับและส่งไปแล้ว'
        } else if (tempData.test_status === 'G') {
          tempData.test_status_name = 'ส่งมอบห้องแล็บแล้ว'
        } else if (tempData.test_status === 'E') {
          tempData.test_status_name = 'ผลออกแล้ว'
        } else if (tempData.test_status === 'L') {
          tempData.test_status_name = 'ราบงานแล้วห้ามแก้ไข'
        } else if (tempData.test_status === 'C') {
          tempData.test_status_name = 'ยกเลิกแล้ว'
        }
        tempData.lab_res.forEach(lab_res => {
          lab_res.res_datetime =
            new Date(lab_res.res_datetime).toLocaleDateString('th-th') +
            ' ' +
            new Date(lab_res.res_datetime).toLocaleTimeString('en-US', {
              hour12: false,
              hour: 'numeric',
              minute: 'numeric'
            })
        })
      })
      state.lisData.data = arrTempData
    }
  },
  setLisDataLoading (state, data) {
    state.lisData.loading = data
  },
  setLisDataStatus (state, data) {
    state.lisData.status = data
  },
  setLisResult (state, data) {
    state.lisResult.code = data.code
    state.lisResult.message = data.message
  },
  setLisResultLoading (state, data) {
    state.lisResult.loading = data
  },
  setLisResultStatus (state, data) {
    state.lisResult.status = data
  },
  setTestData (state, data) {
    if (data == null) {
      state.testData.data = []
    } else {
      state.testData.data = data
    }
  },
  setTestDataLoading (state, data) {
    state.testData.loading = data
  },
  setTestDataStatus (state, data) {
    state.testData.status = data
  },
  setLabReportData (state, data) {
    if (data === null) {
      state.labReportData.data = []
    } else {
      let arrTempData = data
      arrTempData.forEach(tempData => {
        tempData.modify_dt =
          new Date(tempData.modify_dt).toLocaleDateString('th-th') +
          ' ' +
          new Date(tempData.modify_dt).toLocaleTimeString('en-US', {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric'
          })

        if (tempData.test_status === 'R') {
          tempData.test_status_name = 'รอรับสิ่งตรวจ'
        } else if (tempData.test_status === 'O') {
          tempData.test_status_name = 'รับและส่งไปแล้ว'
        } else if (tempData.test_status === 'G') {
          tempData.test_status_name = 'ส่งมอบห้องแล็บแล้ว'
        } else if (tempData.test_status === 'E') {
          tempData.test_status_name = 'ผลออกแล้ว'
        } else if (tempData.test_status === 'L') {
          tempData.test_status_name = 'ราบงานแล้วห้ามแก้ไข'
        } else if (tempData.test_status === 'C') {
          tempData.test_status_name = 'ยกเลิกแล้ว'
        }
        tempData.lab_res.forEach(lab_res => {
          lab_res.res_datetime =
            new Date(lab_res.res_datetime).toLocaleDateString('th-th') +
            ' ' +
            new Date(lab_res.res_datetime).toLocaleTimeString('en-US', {
              hour12: false,
              hour: 'numeric',
              minute: 'numeric'
            })
        })
      })
      state.labReportData.data = arrTempData
    }
  },
  setLabReportDataLoading (state, data) {
    state.labReportData.loading = data
  },
  setLabReportDataStatus (state, data) {
    state.labReportData.status = data
  },
  setLabResData (state, data) {
    if (data === null) {
      state.labResData.data.headers = []
      state.labResData.data.items = []
    } else {
      let _data = data
      let header = []
      let item = []
      if (_data.report_table !== null) {
        for (let i = 0; i < _data.report_table[0].length; i++) {
          header.push({ text: _data.report_table[0][i], value: 'col' + i })
        }
        header.push({ text: 'Attach File', value: 'btn_action' })

        for (let j = 1; j < _data.report_table.length; j++) {
          let jsonItem = {}
          jsonItem.url = []
          for (let k = 0; k < _data.report_table[j].length; k++) {
            if (_data.report_table[j][k].includes('.pdf')) {
              jsonItem['col' + k] = ''
              jsonItem.attach = true
              let _fileName = _data.report_table[j][k].split('.')
              let _fileType = _fileName[1].substring(0, 3)
              let _value = _fileName[0] + '.' + _fileType
              let _url = {
                text: _data.report_table[j][0] + ' : ' + header[k].text,
                value: _value
              }
              jsonItem.url.push(_url)
            } else {
              // jsonItem['col' + k] = _data.report_table[j][k]
              if (k > 0 && k < _data.report_table[j].length - 1) {
                let temp = _.cloneDeep(_data.report_table[j][k])
                if (
                  temp !== null &&
                  temp !== '' &&
                  !Number.isNaN(Number(temp))
                ) {
                  jsonItem['col' + k] = Number(
                    parseFloat(_data.report_table[j][k])
                  ).toLocaleString('en')
                } else {
                  jsonItem['col' + k] = _data.report_table[j][k]
                }
              } else {
                jsonItem['col' + k] = _data.report_table[j][k]
              }
            }
          }
          item.push(jsonItem)
        }
      }
      state.labResData.data.headers = header
      state.labResData.data.items = item
    }
  },
  setLabResDataLoading (state, data) {
    state.labResData.loading = data
  },
  setLabResDataStatus (state, data) {
    state.labResData.status = data
  }
}
const actions = {
  getLabTestData ({ rootState, commit }, payload) {
    commit('setLabTestDataLoading', true)
    commit('setLabTestDataStatus', false)
    api.GetLabTest(
      {
        rid: payload.rid,
        visitId: payload.visitId,
        pon: payload.pon,
        testStatusList: payload.testStatusList,
        groupId: payload.groupId
      },
      message => {
        if (message.data == null) {
          commit('setLabTestData', null)
        } else {
          commit('setLabTestData', message.data)
        }
        commit('setLabTestDataLoading', false)
        commit('setLabTestDataStatus', true)
      },
      error => {
        commit('setLabTestDataLoading', false)
        commit('setLabTestDataStatus', false)
        console.log(error)
      }
    )
  },
  postLabTestData ({ rootState, commit }, payload) {
    payload[0].lab_res.forEach(res => {
      if (res.res_type == 'L') {
        res.res_val = ''
      }
    })
    commit('setLabTestResultLoading', true)
    commit('setLabTestResultStatus', false)
    api.PostLabTest(
      { data: payload, token: rootState.authen.token },
      message => {
        if (message.data == null) {
          commit('setLabTestResult', null)
        } else {
          commit('setLabTestResult', message.data)
        }
        commit('setLabTestResultLoading', false)
        commit('setLabTestResultStatus', true)
      },
      error => {
        commit('setLabTestResultLoading', false)
        commit('setLabTestResultStatus', false)
        console.log(error)
      }
    )
  },
  getLisData ({ rootState, commit }, payload) {
    commit('setLisDataLoading', true)
    commit('setLisDataStatus', false)
    api.GetLabTest(
      {
        rid: payload.rid,
        visitId: payload.visitId,
        pon: payload.pon,
        testStatusList: payload.testStatusList,
        groupId: payload.groupId
      },
      message => {
        if (message.data == null) {
          commit('setLisData', null)
        } else {
          commit('setLisData', message.data)
        }
        commit('setLisDataLoading', false)
        commit('setLisDataStatus', true)
      },
      error => {
        commit('setLisDataLoading', false)
        commit('setLisDataStatus', false)
        console.log(error)
      }
    )
  },
  POST_LAB_RESULT({ commit }, payload) {
    payload[0].lab_res.forEach(res => {
      if (res.res_type == 'L') {
        res.res_val = ''
      }
    })
    return new Promise((resolve,reject)=> {
      api.PostLabTest(
        { data: payload },
        message => {
          if (message.data) {
            commit('setLisResult', message.data)
            resolve(message.data)
          } else {
            commit('setLisResult', null)
            reject()
          }
        },
        error => {
          console.log(error)
          reject(error)
        }
      )
    })
  },
  postLisData ({ commit }, payload) {
    payload[0].lab_res.forEach(res => {
      if (res.res_type == 'L') {
        res.res_val = ''
      }
    })
    commit('setLisResultLoading', true)
    commit('setLisResultStatus', false)
    api.PostLabTest(
      { data: payload },
      message => {
        if (message.data == null) {
          commit('setLisResult', null)
        } else {
          commit('setLisResult', message.data)
        }
        commit('setLisResultLoading', false)
        commit('setLisResultStatus', true)
      },
      error => {
        commit('setLisResultLoading', false)
        commit('setLisResultStatus', false)
        console.log(error)
      }
    )
  },
  getTestData ({ commit, rootState }, payload) {
    commit('setTestDataLoading', true)
    commit('setTestDataStatus', false)
    api.GetTestBySVID(
      { sv_id: payload, token: rootState.authen.token },
      message => {
        if (message.data == null) {
          commit('setTestData', null)
        } else {
          commit('setTestData', message.data)
        }
        commit('setTestDataLoading', false)
        commit('setTestDataStatus', true)
      },
      error => {
        commit('setTestDataLoading', false)
        commit('setTestDataStatus', false)
        console.log(error)
        if (error.response.status === 401) {

        }
      }
    )
  },
  getLabReportData ({ rootState, commit }, payload) {
    commit('setLabReportDataLoading', true)
    commit('setLabReportDataStatus', false)
    api.GetLabTest(
      {
        rid: payload.rid,
        visitId: payload.visitId,
        pon: payload.pon,
        testStatusList: payload.testStatusList,
        groupId: payload.groupId
      },
      message => {
        if (message.data == null) {
          commit('setLabReportData', null)
        } else {
          commit('setLabReportData', message.data)
        }
        commit('setLabReportDataLoading', false)
        commit('setLabReportDataStatus', true)
      },
      error => {
        commit('setLabReportDataLoading', false)
        commit('setLabReportDataStatus', false)
        console.log(error)
        if (error.response.status === 401) {

        }
      }
    )
  },
  getLabResData ({ rootState, commit }, payload) {
    commit('setLabResDataLoading', true)
    commit('setLabResDataStatus', false)
    api.GetLabRes(
      {
        pon_list: payload.pon_list,
        token: rootState.authen.token
      },
      message => {
        if (message.data == null) {
          commit('setLabResData', null)
        } else {
          commit('setLabResData', message.data[0])
        }
        commit('setLabResDataLoading', false)
        commit('setLabResDataStatus', true)
      },
      error => {
        commit('setLabResDataLoading', false)
        commit('setLabResDataStatus', false)
        console.log(error)
        if (error.response.status === 401) {

        }
      }
    )
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
