import { inviteUser } from '@/api/func_app_api'
import { PostUser, GetUserByKeyword } from '@/api/'
const getDefaultState = () => {
  return {
    inviteLoading: false
  }
}
const state = getDefaultState()

const mutations = {
  SET_INVITE_LOADING (state, data) {
    state.inviteLoading = data
  }
}

const actions = {
  POST_INVITE_USER ({ commit, rootState }, data) {
    commit('SET_INVITE_LOADING', true)
    return new Promise((resolve, reject) => {
      GetUserByKeyword({ keyword: data.email }, message => {
        if (message.data) {
          if (Array.isArray(message.data[0].group_permits)) {
            let duplicate = false
            for (let i = 0; i < message.data[0].group_permits.length; i++) {
              if (message.data[0].group_permits[i].group_id === rootState.User.group.group_id) {
                duplicate = true
              }
            }
            if (duplicate) {
              resolve('Already added')
            } else {
              message.data[0].group_permits.push({ group_id: rootState.User.group.group_id })
            }
          } else {
            message.data[0].group_permits = [{ group_id: rootState.User.group.group_id }]
          }
          PostUser({ data: message.data[0] }, message => {
            if (message.data && message.data.code === 1) {
              commit('SET_INVITE_LOADING', false)
              resolve(message.data)
            }
          }, error => {
            console.log(error)
            commit('SET_INVITE_LOADING', false)
            reject(error)
          })
        } else {
          const inviteData = {
            email: data.email,
            group_permits: '|' + rootState.User.group.group_id + '|',
            group_id: rootState.User.group.group_id,
            user_group: data.userGroup
          }
          inviteUser(inviteData, message => {
            if (message.data.code === 1) {
              commit('SET_INVITE_LOADING', false)
              resolve(message.data)
            } else {
              commit('SET_INVITE_LOADING', false)
              reject(message.data)
            }
          }, error => {
            commit('SET_INVITE_LOADING', false)
            reject(error)
          })
        }
      }, error => {
        if (error.response.status === 400) {
          const inviteData = {
            email: data.email,
            group_permits: '|' + rootState.User.group.group_id + '|',
            group_id: rootState.User.group.group_id,
            user_group: data.userGroup
          }
          inviteUser(inviteData, message => {
            if (message.data.code === 1) {
              commit('SET_INVITE_LOADING', false)
              resolve(message.data)
            } else {
              commit('SET_INVITE_LOADING', false)
              reject(message.data)
            }
          }, error => {
            commit('SET_INVITE_LOADING', false)
            reject(error)
          })
        } else {
          reject(error)
        }
      })
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
