import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import th from 'vuetify/lib/locale/th'
// import light from './vuetify/theme'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'mdi' // default - only for display purposes
    },
    theme: {
        themes: {
          light: {
            primary: '#2F2F88', // #3f51b5
            secondary: '#2F2F88', // #b0bec5
          },
          options: {
            cspNonce: 'dQw4w9WgXcQ'
          }
        },
        options: {
          customProperties: true,
          cspNonce: 'dQw4w9WgXcQ'
        }
      },
      lang: {
        locales: { th },
        current: 'th'
      }
});
