import * as api from '../../api'
import { PostVisitChatThread } from '@/api/func_app_api'
import Vue from 'vue'

// import router from '../../router'

const getDefaultState = () => {
  return {
    patientQueingList: { data: null, loading: false, status: false },
    patientTeleList: { data: null, loading: false, status: false },
    patientCompletedList: { data: null, loading: false, status: false },
    patientQueingUpdate: { status: null, loading: false, message: '' },
    patientAppoint: { data: [], loading: false, status: null, message: '' },
    queing: [],
    queingLoading: false,
    queingDate: null
  }
}

const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setPatientTele (state, payload) {
    state.patientTeleList.data = payload
  },
  setPatientTeleLoading (state, payload) {
    state.patientTeleList.loading = payload
  },
  setPatientTeleStatus (state, payload) {
    state.patientTeleList.status = payload
  },
  setPtAppoint (state, payload) {
    payload.forEach(element => {
      element.loading = false
      element.disabled = false
    })
    state.patientAppoint.data = payload
  },
  setPtAppointLoading (state, payload) {
    state.patientAppoint.loading = payload
  },
  SET_APPOINT_ITEM_VALUE (state, { item, value }) {
    if (state.patientAppoint.data.filter((el) => el.visit_id === item.visit_id).length > 0) {
      state.patientAppoint.data.filter((el) => el.visit_id === item.visit_id)[0].loading = value
    }
  },
  setPtAppointStatus (state, payload) {
    state.patientAppoint.status = payload
  },
  setPtAppointMessage (state, payload) {
    state.patientAppoint.message = payload
  },
  setPtQueingList (state, payload) {
    state.patientQueingList.data = payload
  },
  setPtQueingListLoading (state, payload) {
    state.patientQueingList.loading = payload
  },
  setPtQueingListStatus (state, payload) {
    state.patientQueingList.status = payload
  },
  setPtQueingUpdateMessage (state, payload) {
    state.patientQueingUpdate.message = payload
  },
  setPtQueingUpdateStatus (state, payload) {
    state.patientQueingUpdate.status = payload
  },
  setPtQueingUpdateLoading (state, payload) {
    state.patientQueingUpdate.loading = payload
  },
  setPtCompletedList (state, payload) {
    state.patientCompletedList.data = payload
  },
  setPtCompletedListStatus (state, payload) {
    state.patientCompletedList.status = payload
  },
  setPtCompletedListLoading (state, payload) {
    state.patientCompletedList.loading = payload
  },
  SET_QUEING_VISIT (state, data) {
    data.forEach(element => {
      element.loading = false
      element.disabled = false
    })
    state.queing = data
  },
  SET_QUEING_VISIT_LOADING (state, data) {
    state.queingLoading = data
  },
  REMOVE_QUEING_VISIT (state, data) {
    state.queing.splice(state.queing.indexOf(data), 1)
  },
  SET_QUEING_ITEM_DISABLE (state, { item, disabled }) {
    state.queing[state.queing.indexOf(item)].disabled = disabled
  },
  SET_QUEING_ITEM_VALUE (state, { item, element, value }) {
    state.queing[state.queing.indexOf(item)][element] = value
  },
  SET_QUEING_DATE (state, data) {
    state.queingDate = data
  }
}

const actions = {
  GET_QUEING_VISIT ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      commit('SET_QUEING_VISIT_LOADING', true)
      if (!state.queingDate) commit('SET_QUEING_DATE', Vue.prototype.$dayjs().locale('th').format('YYYY-MM-DD'))
      api.getVisit({ datetime: state.queingDate, groupId: rootState.User.group.group_id, userId: 0 }, message => {
        if (message.data) {
          commit('SET_QUEING_VISIT', message.data)
          commit('SET_QUEING_VISIT_LOADING', false)
          resolve(message.data)
        } else {
          commit('SET_QUEING_VISIT', [])
          commit('SET_QUEING_VISIT_LOADING', false)
          resolve(message.data)
        }
      }, error => {
        reject(error)
      })
    })
  },
  POST_CANCEL_VISIT ({ commit }, data) {
    commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: true })
    commit('SET_QUEING_VISIT_LOADING', true)
    let copyData = { ...data }
    copyData.per_status = 5
    return new Promise((resolve, reject) => {
      api.postVisit(copyData, message => {
        commit('REMOVE_QUEING_VISIT', data)
        commit('SET_QUEING_VISIT_LOADING', false)
        resolve(message)
      }, error => {
        commit('SET_QUEING_VISIT_LOADING', false)
        reject(error)
      })
    })
  },
  UPDATE_QUEING_VISIT ({ commit }, data) {
    commit('SET_QUEING_ITEM_VALUE', data)
  },
  UPDATE_QUEING_DATE ({ commit }, data) {
    commit('SET_QUEING_DATE', data)
  },
  POST_SEND_TO_DOCTOR ({ commit }, data) {
    commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: true })
    commit('SET_QUEING_VISIT_LOADING', true)
    let copyData = { ...data }
    copyData.per_status = 2
    copyData.transfer_stamp = Vue.prototype.$dayjs().locale('th').format('YYYY-MM-DD HH:mm:ss')
    return Promise.all([
      new Promise((resolve, reject) => {
        api.postVisit(copyData, message => {
          commit('SET_QUEING_VISIT_LOADING', false)
          resolve(message)
        }, error => {
          commit('SET_QUEING_VISIT_LOADING', false)
          reject(error)
        })
      })
    ])
  },
  POST_CREATE_CHAT_THREAD ({ commit, rootState }, data) {
    commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: true })
    commit('SET_QUEING_VISIT_LOADING', true)
    return new Promise((resolve, reject) => {
      PostVisitChatThread({ group_id: rootState.User.group.group_id, rid: data.rid, visit_id: data.visit_id, user_id: Vue.prototype.$auth.claim.extension_SystemID }, message => {
        commit('SET_QUEING_VISIT_LOADING', false)
        resolve(message)
      }, error => {
        commit('SET_QUEING_VISIT_LOADING', false)
        reject(error)
      })
    })
  },
  POST_SEND_TO_TELE_VISIT ({ commit }, data) {
    commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: true })
    commit('SET_QUEING_VISIT_LOADING', true)
    let copyData = { ...data }
    copyData.per_status = 2
    copyData.is_tele = true
    copyData.transfer_stamp = Vue.prototype.$dayjs().locale('th').format('YYYY-MM-DD HH:mm:ss')
    return Promise.all([
      new Promise((resolve, reject) => {
        api.postVisit(copyData, message => {
          commit('SET_QUEING_VISIT_LOADING', false)
          resolve(message)
        }, error => {
          commit('SET_QUEING_VISIT_LOADING', false)
          reject(error)
        })
      })
    ])
  },
  POST_UPDATE_VISIT_USER ({ commit }, { data, user }) {
    commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: true })
    commit('SET_QUEING_VISIT_LOADING', true)
    let copyData = { ...data }
    // console.log(user)
    copyData.per_status = '1'
    copyData.user_id = user.user_id
    // copyData.per_date = user.ot_st + ' ' + user.start_time
    return Promise.all([
      new Promise((resolve, reject) => {
        api.postVisit(copyData, message => {
          commit('SET_QUEING_VISIT_LOADING', false)
          commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: false })
          resolve(message)
        }, error => {
          commit('SET_QUEING_VISIT_LOADING', false)
          commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: false })
          reject(error)
        })
      })
    ])
  },
  POST_UPDATE_VISIT_VITAL ({ commit }, data) {
    commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: true })
    commit('SET_QUEING_VISIT_LOADING', true)
    return Promise.all([
      new Promise((resolve, reject) => {
        api.postVisit(data, message => {
          commit('SET_QUEING_VISIT_LOADING', false)
          commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: false })
          resolve(message)
        }, error => {
          commit('SET_QUEING_VISIT_LOADING', false)
          commit('SET_QUEING_ITEM_DISABLE', { item: data, disabled: false })
          reject(error)
        })
      })
    ])
  },
  GET_VISIT_BY_RID ({ commit, rootState }, rid) {
    commit('setPtAppointLoading', true)
    return new Promise((resolve, reject) => {
      api.GetQueingVisitByRid({ rid: rid, groupId: rootState.User.group.group_id, userId: 0 }, message => {
        if (message.data == null) {
          commit('setPtAppoint', [])
          resolve()
        } else {
          commit('setPtAppoint', message.data)
          resolve()
        }
        commit('setPtAppointLoading', false)
      }, error => {
        commit('setPtAppointLoading', false)
        console.log(error)
        reject(error)
      })
    })
  },
  GetQueingVisitByDate ({ commit, rootState }, datetime) {
    commit('setPtQueingListLoading', true)
    // commit('setPtQueingListStatus', null)
    // commit('setPtQueingList', [])
    api.GetQueingVisitByDate({ datetime: datetime, groupId: rootState.User.group.group_id, userId: 0 }, message => {
      if (message.data) {
      //  commit('setPtQueingListStatus', false)
        commit('setPtQueingList', message.data)
      } else {
        commit('setPtQueingList', [])
      }
      commit('setPtQueingListLoading', false)
    }, error => {
      commit('setPtQueingList', [])
      commit('setPtQueingListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetTeleVisitByDate ({ commit, rootState }, datetime) {
    commit('setPatientTeleLoading', true)
    api.GetTeleVisitByDate({ datetime: datetime, groupId: rootState.User.group.group_id, userId: 0 }, message => {
      if (message.data == null) {
        commit('setPatientTele', [])
      } else {
        commit('setPatientTele', message.data)
      }
      commit('setPatientTeleLoading', false)
    }, error => {
      commit('setPatientTele', [])
      commit('setPatientTeleLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetWaitingVisitByDate ({ commit, rootState }, datetime) {
    commit('setPtQueingListLoading', true)
    commit('setPtQueingListStatus', null) // rootState.authen.$store.state.User.user.extension_SystemID
    api.GetWaitingVisitByDate({ datetime: datetime, userId: 0, status: 2, groupId: rootState.User.group.group_id }, message => {
      if (message.data == null) {
        commit('setPtQueingListStatus', false)
        commit('setPtQueingList', [])
      } else {
        commit('setPtQueingListStatus', true)
        commit('setPtQueingList', message.data)
      }
      commit('setPtQueingListLoading', false)
    }, error => {
      commit('setPtQueingList', [])
      commit('setPtQueingListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetCompletedVisitByDate ({ commit, rootState }, datetime) {
    commit('setPtCompletedListLoading', true)
    commit('setPtCompletedListStatus', null) // rootState.authen.$store.state.User.user.extension_SystemID
    api.GetWaitingVisitByDate({ datetime: datetime, userId: 0, status: '3|4|5', groupId: rootState.User.group.group_id }, message => {
      if (message.data == null) {
        commit('setPtCompletedListStatus', false)
        commit('setPtCompletedList', [])
      } else {
        commit('setPtCompletedListStatus', true)
        commit('setPtCompletedList', message.data)
      }
      commit('setPtCompletedListLoading', false)
    }, error => {
      commit('setPtCompletedListLoading', false)
      commit('setPtCompletedList', [])
      console.log(error)
    })
  },
  UpdateVisitData ({ commit, dispatch, rootState }, data) {
    commit('setPtQueingUpdateLoading', true)
    commit('setPtQueingUpdateStatus', null)
    api.UpdateVisitById({ data: data }, message => {
      if (message.data.code === 1) {
        commit('setPtQueingUpdateStatus', true)
        commit('setPtQueingUpdateMessage', message.data.message)
        dispatch('GET_QUEING_VISIT')
        dispatch('shifts/GetShiftsInfoByDate', { date: new Date().toISOString().substr(0, 10), grouplist: rootState.user.canBook }, { root: true })
      } else {
        commit('setPtQueingUpdateMessage', message.data.message)
        commit('setPtQueingUpdateStatus', false)
      }
      commit('setPtQueingUpdateLoading', false)
    }, error => {
      commit('setPtQueingUpdateLoading', false)
      console.log(error)
    })
  },
  SAVE_VISIT_DATA ({ commit, dispatch, rootState }, data) {
    return new Promise((resolve, reject) => {
      api.UpdateVisitById({ data: data }, message => {
        // dispatch('patient/RESET_PATIENT', '', { root: true })
        resolve()
      }, error => {
        console.log(error)
        reject(error)
      })
    })
  },
  CreateAppointData ({ commit }, data) {
    commit('setPtAppointLoading', true)
    commit('setPtAppointStatus', null)
    return new Promise((resolve, reject) => {
      api.UpdateVisitById({ data: data }, message => {
        if (message.data.code === 1) {
          commit('setPtAppointStatus', true)
          commit('setPtAppointMessage', message.data.message)
        } else {
          commit('setPtAppointStatus', false)
          commit('setPtAppointMessage', message.data.message)
        }
        commit('setPtAppointLoading', false)
        resolve()
      }, error => {
        commit('setPtAppointLoading', false)
        console.log(error)
        reject(error)
      })
    })
  },
  UPDATE_VISIT_BY_RID ({ commit }, data) {
    commit('SET_APPOINT_ITEM_VALUE', { item: data, value: true })
    return new Promise((resolve, reject) => {
      api.UpdateVisitById({ data: data }, message => {
        if (message.data.code === 1) {
          commit('setPtAppointMessage', message.data.message)
        } else {
          commit('setPtAppointMessage', [])
        }
        commit('SET_APPOINT_ITEM_VALUE', { item: data, value: false })
        resolve()
      }, error => {
        console.log(error)
        commit('SET_APPOINT_ITEM_VALUE', { item: data, value: false })
        reject(error)
      })
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
