import Vue from 'vue'
import Vuex from 'vuex'
import authen from '@/store/modules/authen'
import patient from '@/store/modules/patient'
import visit from '@/store/modules/visit'
import shifts from '@/store/modules/shifts'
import fasttrack from '@/store/modules/fasttrack'
import label from '@/store/modules/label'
import stock from '@/store/modules/stock'
import diag from '@/store/modules/diag'
import order from '@/store/modules/order'
import report from '@/store/modules/report'
import bag from '@/store/modules/bag'
import allergy from '@/store/modules/allergy'
import uset from '@/store/modules/uset'
import bed from '@/store/modules/bed'
import doc from '@/store/modules/doc'
import lis from '@/store/modules/lis'
import lab from '@/store/modules/lab'
import apply from '@/store/modules/apply'
import userAccount from '@/store/modules/userAccount'
// import blobstorage from '@/store/modules/blobstorage'
import staff from '@/store/modules/staff'
import User from '@/store/modules/user'
import File from '@/store/modules/file'
import Member from '@/store/modules/member'
import MasterItem from '@/store/modules/masterItem'
import VuexPersistence from 'vuex-persist'


Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['authen', 'User']
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [vuexLocal.plugin],
  namespaced: true,
  modules: {
    authen,
    patient,
    visit,
    shifts,
    fasttrack,
    label,
    stock,
    diag,
    order,
    report,
    bag,
    allergy,
    uset,
    bed,
    doc,
    lis,
    lab,
    apply,
    // blobstorage,
    staff,
    userAccount,
    User,
    File,
    Member,
    MasterItem
  }
})
