import * as api from '../../api'
import router from '../../router'

const getDefaultState = () => {
  return {
    bedList: { data: [], loading: false, status: null, post: null },
    bedListAll: { data: [], loading: false, status: null, post: null },
    bedInfo: { data: [], loading: false, status: null, post: null },
    bedCreatedList: { data: [], loading: false, status: null, post: null }
  }
}
const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setBedList (state, data) {
    state.bedList.data = data
  },
  setBedLoading (state, data) {
    state.bedList.loading = data
  },
  setBedStatus (state, data) {
    state.bedList.status = data
  },
  setBedPost (state, data) {
    state.bedList.post = data
  },
  setBedListAll (state, data) {
    state.bedListAll.data = data
  },
  setBedListAllLoading (state, data) {
    state.bedListAll.loading = data
  },
  setBedListAllStatus (state, data) {
    state.bedListAll.status = data
  },
  setBedListAllPost (state, data) {
    state.bedListAll.post = data
  },
  setBedInfo (state, data) {
    state.bedInfo.data = data
  },
  setBedInfoLoading (state, data) {
    state.bedInfo.loading = data
  },
  setBedInfoStatus (state, data) {
    state.bedInfo.status = data
  },
  setBedCreatedList (state, data) {
    state.bedCreatedList.data = data
  },
  setBedCreatedListLoading (state, data) {
    state.bedCreatedList.loading = data
  },
  setBedCreatedListStatus (state, data) {
    state.bedCreatedList.status = data
  }
}

const actions = {
  ResetBed ({ commit }) {
    commit('resetstate')
  },
  GetAvailableBed ({ commit, rootState }) {
    commit('setBedLoading', true)
    commit('setBedStatus', null)
    api.GetAvailableBed({ groupid: rootState.User.group.group_id }, message => {
      if (message.data !== null) {
        commit('setBedList', message.data)
      } else {
        commit('setBedList', [])
      }
      commit('setBedLoading', false)
    }, error => {
      commit('setBedList', [])
      commit('setBedLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetAllBed ({ commit, rootState }) {
    commit('setBedListAllLoading', true)
    commit('setBedListAllStatus', null)
    api.GetAllBed({ groupid: rootState.User.group.group_id, token: rootState.authen.token }, message => {
      if (message.data !== null) {
        commit('setBedListAll', message.data)
      } else {
        commit('setBedListAll', [])
      }
      commit('setBedListAllLoading', false)
    }, error => {
      commit('setBedListAll', [])
      commit('setBedListAllLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetCreatedBed ({ commit, rootState }) {
    commit('setBedCreatedListLoading', true)
    commit('setBedCreatedListStatus', null)
    api.GetCreatedBed({ groupid: rootState.User.group.group_id }, message => {
      if (message.data !== null) {
        commit('setBedCreatedList', message.data)
        commit('setBedCreatedListStatus', true)
      } else {
        commit('setBedCreatedList', [])
        commit('setBedCreatedListStatus', false)
      }
      commit('setBedCreatedListLoading', false)
    }, error => {
      commit('setBedCreatedList', [])
      commit('setBedCreatedListStatus', false)
      commit('setBedCreatedListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  PostBed ({ rootState, commit }, data) {
    commit('setBedLoading', true)
    commit('setBedPost', false)
    api.PostBed({ data: data, token: rootState.authen.token }, message => {
      if (message.data !== null) {
        if (message.data.code === 1) {
          commit('setBedPost', true)
        } else {
          commit('setBedPost', false)
        }
      } else {
        commit('setBedPost', false)
      }
      commit('setBedLoading', false)
    }, error => {
      commit('setBedLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
