import * as api from '../../api'
// import router from '../../router'

const getDefaultState = () => {
  return {
    applyPost: { loading: false, status: null, message: '' }
  }
}
const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setApplyPostLoading (state, data) {
    state.applyPost.loading = data
  },
  setApplyPostStatus (state, data) {
    state.applyPost.status = data
  },
  setApplyPostMessage (state, data) {
    state.applyPost.message = data
  }
}

const actions = {
  ResetApply ({ commit }) {
    commit('resetstate')
  },
  PostApply ({ rootState, commit }, { data }) {
    commit('setApplyPostLoading', true)
    commit('setApplyPostStatus', null)
    api.PostApply({ data: data }, message => {
      if (message.data !== null) {
        if (message.data.code === 1) {
          commit('setApplyPostStatus', true)
          commit('setApplyPostMessage', message.data.message)
        } else {
          commit('setApplyPostStatus', false)
          commit('setApplyPostMessage', message.data.message)
        }
      } else {
        commit('setApplyPostStatus', false)
      }

      commit('setApplyPostLoading', false)
    }, error => {
      commit('setApplyPostLoading', false)
      console.log(error)
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
