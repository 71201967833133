import * as api from '../../api'
// import router from '../../router'
const getDefaultState = () => {
  return {
    patientData: { data: [], status: false, loading: false },
    patientResult: { code: null, message: null, status: false, loading: false },
    patientInfo: { data: null, loading: false, status: null, admit: false },
    patientList: { data: [], loading: false, status: false },
    insuranceData: { data: [], status: false, loading: false },
    patientInformation: { data: [], status: false, loading: false },
    patientVisit: { data: [], status: false, loading: false },
    patientLoading: false,
    patientRelativeData: { data: [], status: false, loading: false },
    patientRid: { data: [], status: false, loading: false },
    searchResult: [],
    patient: {}
  }
}

const state = getDefaultState()
const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setPatientData (state, data) {
    if (data == null) {
      state.patientData.data = []
    } else {
      var arrTempData = data
      arrTempData.forEach(tempData => {
        if (tempData.sex == 'M') {
          tempData.sex_name = 'ชาย'
        } else if (tempData.sex == 'F') {
          tempData.sex_name = 'หญิง'
        } else {
          tempData.sex_name = 'ไม่ระบุ'
        }
      })
      state.patientData.data = arrTempData
    }
  },
  setPatientDataLoading (state, data) {
    state.patientData.loading = data
  },
  setPatientDataStatus (state, data) {
    state.patientData.status = data
  },
  setPatientResult (state, data) {
    state.patientResult.code = data.code
    state.patientResult.message = data.message
  },
  setPatientResultLoading (state, data) {
    state.patientResult.loading = data
  },
  setPatientResultStatus (state, data) {
    state.patientResult.status = data
  },
  setPatientInfo (state, payload) {
    state.patientInfo.data = payload
    if (state.patientInfo.data.visits === null) {
      state.patientInfo.data.visits = []
    }
  },
  setPatientInfoLoading (state, payload) {
    state.patientInfo.loading = payload
  },
  setPatientStatus (state, payload) {
    state.patientInfo.status = payload
  },
  setPatientAdmit (state, payload) {
    state.patientInfo.admit = payload
  },
  setPatientListData (state, payload) {
    if (payload === null) {
      state.patientList.data = []
    } else {
      state.patientList.data = payload
    }
  },
  setPatientListLoading (state, payload) {
    state.patientList.loading = payload
  },
  setPatientListStatus (state, payload) {
    state.patientList.status = payload
  },
  setInsuranceData (state, data) {
    if (data == null) { state.insuranceData.data = [] } else { state.insuranceData.data = data }
  },
  setInsuranceDataLoading (state, data) {
    state.insuranceData.loading = data
  },
  setInsuranceDataStatus (state, data) {
    state.insuranceData.status = data
  },
  setPatientInformation (state, data) {
    state.patientInformation.data = []
    if (data == null) { state.patientInformation.data = [] } else { state.patientInformation.data = data }
  },
  setPatientInformationLoading (state, data) {
    state.patientInformation.loading = data
  },
  setPatientInformationStatus (state, data) {
    state.patientInformation.status = data
  },
  setPatientVisitData (state, payload) {
    if (payload === null) {
      state.patientVisit.data = []
    } else {
      state.patientVisit.data = payload
      if (state.patientVisit.data.visits === null) {
        state.patientVisit.data.visits = []
      }
    }
  },
  setPatientVisitLoading (state, payload) {
    state.patientVisit.loading = payload
  },
  setPatientVisitStatus (state, payload) {
    state.patientVisit.status = payload
  },
  setPatientRelativeData (state, data) {
    if (data == null) {
      state.patientRelativeData.data = []
    } else {
      state.patientRelativeData.data = data
    }
  },
  setPatientRelativeDataLoading (state, data) {
    state.patientRelativeData.loading = data
  },
  setPatientRelativeDataStatus (state, data) {
    state.patientRelativeData.status = data
  },
  setPatientRid (state, data) {
    state.patientRid.data = []
    if (data == null) { state.patientRid.data = [] } else { state.patientRid.data = data }
  },
  setPatientRidLoading (state, data) {
    state.patientRid.loading = data
  },
  setPatientRidStatus (state, data) {
    state.patientRid.status = data
  },
  SET_SEARCH_RESULT (state, data) {
    state.searchResult = data
  },
  SET_PATIENT (state, data) {
    state.patient = data
  }
}
const actions = {
  SEARCH_PATIENT ({ commit, rootState }, keyword) {
    return new Promise((resolve, reject) => {
      api.GetPatientByKeyword({ keyword: keyword, groupId: rootState.User.group.group_id }, message => {
        if (message.data) {
          commit('SET_SEARCH_RESULT', message.data)
          resolve(message.data)
        } else {
          commit('SET_SEARCH_RESULT', [])
          resolve([])
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_PATIENT_BY_ID ({ commit, rootState }, id) {
    // To do: check existing id before fetching data to reduce api call
    return new Promise((resolve, reject) => {
      api.GetPatientById({ id: id, groupId: rootState.User.group.group_id }, message => {
        if (message.data) {
          commit('SET_PATIENT', message.data[0])
          resolve(message.data[0])
        } else {
          commit('SET_PATIENT', {})
          resolve({})
        }
      }, error => {
        reject(error)
      })
    })
  },
  RESET_PATIENT ({ commit }) {
    commit('setPatientInfo', {})
  },
  RESET_PATIENT_RESULT ({ commit }) {
    commit('SET_SEARCH_RESULT', [])
  },
  initialPatientData ({ commit }) {
    commit('setPatientDataLoading', false)
    commit('setPatientDataStatus', false)
    commit('setPatientData', null)
  },
  getPatientData ({ rootState, commit }, payload) {
    commit('setPatientDataLoading', true)
    commit('setPatientDataStatus', false)
    if (payload.keyword == null || payload.keyword == '') { payload.keyword = '%' }
    api.GetPatient({ groupId: payload.groupId, patientStage: payload.patientStage, limitList: payload.limitList, cardId: payload.cardId, keyword: payload.keyword }, message => {
      if (message.data == null) {
        commit('setPatientData', null)
      } else {
        commit('setPatientData', message.data)
      }
      commit('setPatientDataLoading', false)
      commit('setPatientDataStatus', true)
    }, error => {
      commit('setPatientDataLoading', false)
      commit('setPatientDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  postPatient ({ rootState, commit }, payload) {
    commit('setPatientResultLoading', true)
    commit('setPatientResultStatus', false)
    api.PostPatient({ body: payload, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setPatientResult', null)
      } else {
        commit('setPatientResult', message.data)
      }
      commit('setPatientResultLoading', false)
      commit('setPatientResultStatus', true)
    }, error => {
      commit('setPatientResultLoading', false)
      commit('setPatientResultStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetPatientByCardId ({ commit, rootState }, cardId) {
    commit('setPatientInfoLoading', true)
    commit('setPatientStatus', null)
    api.GetPatientByCardId({ cardId: cardId, groupId: rootState.User.group.group_id }, message => {
      if (message.data == null) {
        // commit('setPatientStatus', false)
        commit('setPatientInfo', null)
        console.log('not found')
      } else {
        commit('setPatientStatus', true)
        commit('setPatientInfo', message.data[0])
      }
      commit('setPatientInfoLoading', false)
    }, error => {
      commit('setPatientStatus', false)
      commit('setPatientInfoLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetPatientByKeyword ({ commit, rootState }, keyWord) {
    commit('setPatientListLoading', true)
    commit('setPatientListStatus', null)
    commit('setPatientListData', [])
    api.GetPatientByKeyword({ keyword: keyWord, groupId: rootState.User.group.group_id, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setPatientListStatus', false)
        commit('setPatientListData', [])
      } else {
        commit('setPatientListStatus', true)
        commit('setPatientListData', message.data)
      }
      commit('setPatientListLoading', false)
    }, error => {
      commit('setPatientListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GET_PATIENT_BY_PHYSICIAN ({ commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      api.GetPatientById({ id: id, groupId: rootState.User.group.group_id }, message => {
        if (message.data) {
          commit('setPatientInfo', message.data[0])
          resolve(message.data[0])
        } else {
          commit('setPatientInfo', {})
          resolve({})
        }
      }, error => {
        reject(error)
      })
    })
  },
  GetPatientById ({ commit, rootState }, id) {
    commit('setPatientInfoLoading', true)
    commit('setPatientStatus', null)
    api.GetPatientById({ id: id, groupId: rootState.User.group.group_id }, message => {
      if (message.data == null) {
        // commit('setPatientStatus', false)
        commit('setPatientInfo', null)
      } else {
        commit('setPatientStatus', true)
        commit('setPatientInfo', message.data[0])
      }
      commit('setPatientInfoLoading', false)
    }, error => {
      commit('setPatientStatus', false)
      commit('setPatientInfoLoading', false)
      console.log(error)
    })
  },
  GetPatientAdmitById ({ commit, rootState }, id) {
    commit('setPatientInfoLoading', true)
    commit('setPatientAdmit', null)
    api.GetPatientById({ id: id, groupId: rootState.User.group.group_id }, message => {
      if (message.data == null) {
        commit('setPatientAdmit', false)
        commit('setPatientInfo', null)
      } else {
        commit('setPatientAdmit', true)
        commit('setPatientInfo', message.data[0])
      }
      commit('setPatientInfoLoading', false)
    }, error => {
      commit('setPatientStatus', false)
      commit('setPatientInfoLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetPatientVisitById ({ commit, rootState }, id) {
    commit('setPatientVisitLoading', true)
    commit('setPatientVisitStatus', null)
    commit('setPatientVisitData', [])
    api.GetPatientById({ id: id, groupId: rootState.User.group.group_id, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setPatientVisitStatus', false)
        commit('setPatientVisitData', [])
      } else {
        commit('setPatientVisitStatus', true)
        commit('setPatientVisitData', message.data[0])
      }
      commit('setPatientVisitLoading', false)
    }, error => {
      commit('setPatientVisitStatus', false)
      commit('setPatientVisitLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  SetPatientStatus ({ commit }, status) {
    commit('setPatientStatus', status)
  },
  getInsuranceData ({ rootState, commit }, payload) {
    commit('setInsuranceDataLoading', true)
    commit('setInsuranceDataStatus', false)
    api.GetInsurance({ policyNo: payload.policyNo, rid: payload.rid, groupId: payload.groupId }, message => {
      if (message.data == null) {
        commit('setInsuranceData', null)
      } else {
        commit('setInsuranceData', message.data)
      }
      commit('setInsuranceDataLoading', false)
      commit('setInsuranceDataStatus', true)
    }, error => {
      commit('setInsuranceDataLoading', false)
      commit('setInsuranceDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  getPatientInformation ({ rootState, commit }, payload) {
    commit('setPatientInformationLoading', true)
    commit('setPatientInformationStatus', false)
    api.GetPatient({ groupId: payload.groupId, patientStage: payload.patientStage, limitList: 1, cardId: payload.cardId, keyword: payload.keyword, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setPatientInformation', null)
      } else {
        commit('setPatientInformation', message.data[0])
      }
      commit('setPatientInformationLoading', false)
      commit('setPatientInformationStatus', true)
    }, error => {
      commit('setPatientInformationLoading', false)
      commit('setPatientInformationStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  ResetPatientState ({ commit }) {
    commit('resetstate')
  },
  getPatientRalativeData ({ rootState, commit }, payload) {
    commit('setPatientRelativeDataLoading', true)
    commit('setPatientRelativeDataStatus', false)
    api.GetPatient({ groupId: payload.groupId, patientStage: 'A', limitList: 5, cardId: '', keyword: payload.keyword }, message => {
      if (message.data == null) {
        commit('setPatientRelativeData', null)
      } else {
        commit('setPatientRelativeData', message.data)
      }
      commit('setPatientRelativeDataLoading', false)
      commit('setPatientRelativeDataStatus', true)
    }, error => {
      commit('setPatientRelativeDataLoading', false)
      commit('setPatientRelativeDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  ResetPatientList ({ commit }) {
    commit('setPatientListData', [])
  },
  getPatientRid ({ rootState, commit }, payload) {
    commit('setPatientRidLoading', true)
    commit('setPatientRidStatus', false)
    api.GetPatient({ groupId: payload.groupId, patientStage: payload.patientStage, limitList: 1, cardId: payload.cardId, keyword: payload.keyword, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setPatientRid', null)
      } else {
        commit('setPatientRid', message.data[0])
      }
      commit('setPatientRidLoading', false)
      commit('setPatientRidStatus', true)
    }, error => {
      commit('setPatientRidLoading', false)
      commit('setPatientRidStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
