import {getFile, postFile} from '@/api/func_app_api'

const state = () => ({

})

const mutations = {

}

const actions = {
    /* GET_USER_AVATAR (file_name) */
    GET_USER_AVATAR ({}, file_name) {
        console.log(file_name)
        return new Promise((resolve, reject) => {
            getFile({blob_name: file_name ,blob_type: 1, group_id: 0}, message=> {
                if (message.data.result[0].url) {
                    resolve(message.data.result[0].url)
                } else {
                    resolve(null)
                }
               
            }, error=> {
                reject(error)
            })
        })
    },
     /* GET_PATIENT_AVATAR (file_name) */
     GET_PATIENT_AVATAR ({ rootState },file_name) {
        return new Promise((resolve, reject) => {
            getFile({blob_name: file_name ,blob_type: 2, group_id: rootState.User.group.group_id}, message=> {
                if (message.data.result[0].url) {
                    resolve(message.data.result[0].url)
                } else {
                    resolve(null)
                }
               
            }, error=> {
                reject(error)
            })
        })
    },
      /* GET_MEDICAL_FILE (file_name) */
    GET_MEDICAL_FILE ({ rootState },file_name) {
        return new Promise((resolve, reject) => {
            getFile({blob_name: file_name ,blob_type: 3, group_id: rootState.User.group.group_id}, message=> {
                if (message.data.result[0].url) {
                    resolve(message.data.result[0].url)
                } else {
                    resolve(null)
                }
               
            }, error=> {
                reject(error)
            })
        })
    },
      /* GET_LAB_FILE (file_name) */
      GET_LAB_FILE ({rootState},file_name) {
        return new Promise((resolve, reject) => {
            getFile({blob_name: file_name ,blob_type: 4, group_id: rootState.User.group.group_id}, message=> {
                if (message.data.result[0].url) {
                    resolve(message.data.result[0].url)
                } else {
                    resolve(null)
                }
               
            }, error=> {
                reject(error)
            })
        })
    },
     /* GET_SHIFT_STAMP_FILE (file_name) */
     GET_SHIFT_STAMP_FILE ({rootState},file_name) {
        return new Promise((resolve, reject) => {
            getFile({blob_name: file_name ,blob_type: 5, group_id: rootState.User.group.group_id}, message=> {
                if (message.data.result[0].url) {
                    resolve(message.data.result[0].url)
                } else {
                    resolve(null)
                }
               
            }, error=> {
                reject(error)
            })
        })
    },
    /* GET_OTHER_FILE (file_name) */
    GET_OTHER_FILE ({rootState},file_name) {
        return new Promise((resolve, reject) => {
            getFile({blob_name: file_name ,blob_type: 6, group_id: rootState.User.group.group_id}, message=> {
                if (message.data.result[0].url) {
                    resolve(message.data.result[0].url)
                } else {
                    resolve(null)
                }
               
            }, error=> {
                reject(error)
            })
        })
    },
    /* UPLOAD_USER_AVATAR (captcha, file_type, file_encode) */
    UPLOAD_USER_AVATAR({commit},{captcha, file_type, file_encode}) {
        return new Promise((resolve, reject) => {
            postFile({ blob_type: 1,captcha: captcha,file_type: file_type,file_encode: file_encode }, message => {
                if (message.data.result[0].url) {
                    commit('User/SET_USER_AVATAR', message.data.result[0].url, { root: true })
                    resolve(message.data.result[0].url)
                } else {
                    resolve(null)
                }
            }, error=> {
                reject(error)
            })
        })
    },
     /* UPLOAD_PATIENT_AVATAR (captcha, file_type, file_encode, rid) */
     UPLOAD_PATIENT_AVATAR({rootState},{captcha, file_type, file_encode, rid}) {
            return new Promise((resolve, reject) => {
                postFile({ blob_type: 2,captcha: captcha,file_type: file_type,file_encode: file_encode, group_id: rootState.User.group.group_id, rid: rid }, message => {
                    if (message.data.result[0].url) {
                        resolve(message.data.result[0].url)
                    } else {
                        resolve(null)
                    }
                }, error=> {
                    reject(error)
                })
            })
    },
    /* UPLOAD_MEDICAL_FILE (captcha, file_type, file_encode, visit_id,rid, note) */
    UPLOAD_MEDICAL_FILE({rootState},{captcha, file_type, file_encode, visit_id,rid,note}) {
        return new Promise((resolve, reject) => {
            postFile({ blob_type: 3,captcha: captcha,file_type: file_type,file_encode: file_encode,group_id: rootState.User.group.group_id, visit_id: visit_id,rid: rid,note: note }, message => {
                    if (message.data.result[0].url) {
                        resolve(message.data.result[0].url)
                    } else {
                        resolve(null)
                    }
        }, error=> {
            reject(error)
            })
        })
    },
    /* UPLOAD_LAB_FILE (captcha, file_type, file_encode, visit_id,rid,note, pon, sv_order) */
    UPLOAD_LAB_FILE({rootState},{captcha, file_type, file_encode, visit_id,rid,note, pon, sv_order}) {
        return new Promise((resolve, reject) => {
            postFile({ blob_type: 4,captcha: captcha,file_type: file_type,file_encode: file_encode,group_id: rootState.User.group.group_id, visit_id: visit_id,rid: rid,note: note, pon: pon, sv_order: sv_order }, message => {
                if (message.data.result[0].url) {
                    resolve(message.data.result[0].url)
                } else {
                    resolve(null)
                }
            }, error=> {
                reject(error)
            })
        })
    },
    /* UPLOAD_SHIFT_STAMP_FILE (captcha, file_type, file_encode) */
    UPLOAD_SHIFT_STAMP_FILE({},{captcha, file_type, file_encode}) {
        return new Promise((resolve, reject) => {
            postFile({ blob_type: 5,captcha: captcha,file_type: file_type,file_encode: file_encode }, message => {
                if (message.data.result[0].fileName) {
                    resolve(message.data.result[0].fileName)
                } else {
                    resolve(null)
                }
            }, error=> {
                reject(error)
            })
        })
    },
     /* UPLOAD_OTHER_FILE (captcha, file_type, file_encode, filename) */
     UPLOAD_OTHER_FILE({},{captcha, file_type, file_encode, filename}) {
        return new Promise((resolve, reject) => {
            postFile({ blob_type: 6,captcha: captcha,file_type: file_type,file_encode: file_encode, filename: filename }, message => {
                if (message.data.result[0].url) {
                    resolve(message.data.result[0].url)
                } else {
                    resolve(null)
                }
            }, error=> {
                reject(error)
            })
        })
    },

}

export default {
    namespaced: true,
    // state,
    actions,
    // mutations
  }
  