import * as api from '../../api'
import router from '../../router'

const getDefaultState = () => {
  return {
    usetList: { data: [], loading: false, status: null },
    usetInfo: { data: [], loading: false, status: null, post: null, postloading: false }
  }
}
const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  SetUsetList (state, data) {
    state.usetList.data = data
  },
  SetUsetListLoading (state, data) {
    state.usetList.loading = data
  },
  SetUsetListStatus (state, data) {
    state.usetList.status = data
  },
  SetUsetInfo (state, data) {
    state.usetInfo.data = data
  },
  SetUsetInfoLoading (state, data) {
    state.usetInfo.loading = data
  },
  SetUsetInfoStatus (state, data) {
    state.usetInfo.status = data
  },
  SetUsetInfoPost (state, data) {
    state.usetInfo.post = data
  },
  SetUsetInfoPostLoading (state, data) {
    state.usetInfo.postloading = data
  }
}

const actions = {
  ResetUset ({ commit }) {
    commit('resetstate')
  },
  GetUsetList ({ rootState, commit }, { userId, groupId, usetId }) {
    commit('SetUsetListLoading', true)
    commit('SetUsetListStatus', null)
    api.GetSet({ userId: userId, groupId: groupId, usetId: usetId }, message => {
      commit('SetUsetListLoading', false)
      if (message.data == null) {
        commit('SetUsetListStatus', false)
        commit('SetUsetList', [])
      } else {
        commit('SetUsetListStatus', true)
        commit('SetUsetList', message.data)
      }
    }, error => {
      commit('SetUsetListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetUsetInfo ({ rootState, commit }, { userId, groupId, usetId }) {
    commit('SetUsetInfoLoading', true)
    commit('SetUsetInfoStatus', null)
    commit('SetUsetInfo', [])
    api.GetSet({ userId: userId, groupId: groupId, usetId: usetId, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('SetUsetInfoStatus', false)
        commit('SetUsetInfo', [])
      } else {
        commit('SetUsetInfoStatus', true)
        commit('SetUsetInfo', message.data)
      }
      commit('SetUsetInfoLoading', false)
    }, error => {
      commit('SetUsetInfoLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  PostUsetInfo ({ rootState, commit }, data) {
    commit('SetUsetInfoPostLoading', true)
    commit('SetUsetInfoPost', null)
    api.PostSet({ data: data }, message => {
      commit('SetUsetInfoPostLoading', false)
      // console.log(message.data)
      if (message.data === null) {
        commit('SetUsetInfoPost', false)
      } else {
        if (message.data.code === 1) {
          commit('SetUsetInfoPost', true)
        } else {
          commit('SetUsetInfoPost', false)
        }
      }
    }, error => {
      commit('SetUsetInfoPostLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
