import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@fontsource/kanit'
import './plugins/dayjs'
//import VueAnalytics from 'vue-analytics'
import { msalInstance } from './authConfig'
import { msalPlugin } from './plugins/msalPlugin'
import { EventType } from '@azure/msal-browser'

import * as io from 'socket.io-client'
window.io = io

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

Vue.use(msalPlugin, msalInstance)

// console.log(msalInstance.getAllAccounts()[0])

// Vue.use(VueAnalytics, {
//   id: 'UA-39887973-2',
//   // userId: msalInstance.getAllAccounts()[0].localAccountId,
//   autoTracking: {
//     exception: true,
//     exceptionLogs: true
//   },
//   debug: {
//     enabled: false,
//     sendHitTask: process.env.NODE_ENV === 'production'
//   },
//   disabled: () => {
//     return true
//   },
//   store,
//   router
// })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
