import * as api from '../../api'
import router from '../../router'
const getDefaultState = () => {
  return {
    dxList: { data: [], loading: false, status: null },
    drugList: { data: [], loading: false, status: null },
    drugListFT: { data: [], loading: false, status: null, post: null },
    diagListFT: { data: [], loading: false, status: null, post: null, postloading: false },
    visitNumber: null
  }
}
const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setDrugListFT (state, data) {
    state.drugListFT.data = data
  },
  setDrugListFTLoading (state, data) {
    state.drugListFT.loading = data
  },
  setDrugListFTStatus (state, data) {
    state.drugListFT.status = data
  },
  setDrugListFTPost (state, data) {
    state.drugListFT.post = data
  },
  setDiagListFT (state, data) {
    if (data.nquiz_list) {
      const result = Array.from(new Set(data.nquiz_list.map(s => s.code_diag)))
        .map(codeDiag => {
          return {
            code_diag: codeDiag,
            symptom: data.nquiz_list.find(s => s.code_diag === codeDiag).symptom,
            lmp: data.nquiz_list.find(s => s.code_diag === codeDiag).lmp,
            rid: data.nquiz_list.find(s => s.code_diag === codeDiag).rid
          }
        })
      data.nquiz_list = result
      state.diagListFT.data = data
    }
  },
  setDiagListFTLoading (state, data) {
    state.diagListFT.loading = data
  },
  setDiagListFTStatus (state, data) {
    state.diagListFT.status = data
  },
  setDiagListFTPost (state, data) {
    state.diagListFT.post = data
  },
  setDiagListFTPostLoading (state, data) {
    state.diagListFT.postloading = data
  },
  setDxList (state, data) {
    state.dxList.data = data
  },
  setDxListLoading (state, data) {
    state.dxList.loading = data
  },
  setDxListStatus (state, data) {
    state.dxList.status = data
  },
  setDrugList (state, data) {
    state.drugList.data = data
  },
  setDrugListLoading (state, data) {
    state.drugList.loading = data
  },
  setDrugListStatus (state, data) {
    state.drugList.status = data
  },
  setVisitNumber (state, data) {
    state.visitNumber = data
  },
  updateRid (state, data) {
    if (state.dxList.data.length > 0) {
      for (var i = 0; i < state.dxList.data.length; i++) {
        state.dxList.data[i].rid = data
      }
    }
  }
}

const actions = {
  UpdateRid ({ commit }, rid) {
    commit('updateRid', rid)
  },
  GetDrugFromDx ({ rootState, commit }, keyword) {
    commit('setDxListLoading', true)
    api.GetDrugFromDx({ keyword: keyword }, message => {
      if (message.data == null) {
        commit('setDxList', [])
      } else {
        commit('setDxList', message.data)
      }
      commit('setDxListLoading', false)
    }, error => {
      commit('setDxListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  PostDx ({ rootState, commit }, data) {
    commit('setDxListLoading', true)
    commit('setDxListStatus', null)
    api.PostDx({ dxList: data, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setDxListStatus', false)
      } else {
        if (message.data.code === 1) {
          commit('setVisitNumber', message.data.message)
        }
        commit('setDxListStatus', true)
      }
      commit('setDxListLoading', false)
    }, error => {
      commit('setDxListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetSuggestedDrugFromVisit ({ rootState, commit }, visitId) {
    commit('setDrugListLoading', true)
    commit('setDrugList', [])
    api.GetSuggestedDrugFromVisit({ visitId: visitId }, message => {
      if (message.data == null) {
        commit('setDrugList', [])
      } else {
        commit('setDrugList', message.data)
      }
      commit('setDrugListLoading', false)
    }, error => {
      commit('setDrugListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  PostDrug ({ rootState, commit }, data) {
    commit('setDrugListLoading', true)
    commit('setDrugListStatus', null)
    // console.log('ApiCall')
    api.PostDrug({ drugList: data, token: rootState.authen.token }, message => {
      // console.log(message)
      if (message.data == null) {
        commit('setDrugListStatus', false)
      } else {
        if (message.data.code === 1) {
          commit('setDrugListStatus', true)
        } else {
          commit('setDrugListStatus', false)
        }
      }
      commit('setDrugListLoading', false)
    }, error => {
      commit('setDrugListLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetDrugListFromFT ({ rootState, commit }, { rid, userId, lmp }) {
    commit('setDrugListFTLoading', true)
    commit('setDrugListFT', [])
    api.GetDrugListFromFT({ rid: rid, userId: userId, lmp: lmp }, message => {
      if (message.data == null) {
        commit('setDrugListFT', [])
      } else {
        commit('setDrugListFT', message.data)
      }
      commit('setDrugListFTLoading', false)
    }, error => {
      commit('setDrugListFTLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  GetDiagFromFT ({ rootState, commit }, { rid, userId, lmp, node }) {
    commit('setDiagListFTLoading', true)
    commit('setDiagListFT', [])
    api.GetDiagFromFT({ rid: rid, userId: userId, lmp: lmp, node: node }, message => {
      if (message.data == null) {
        commit('setDiagListFT', [])
      } else {
        commit('setDiagListFT', message.data)
      }
      commit('setDiagListFTLoading', false)
    }, error => {
      commit('setDiagListFTLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  PostNreverse ({ rootState, commit }, data) {
    commit('setDiagListFTPostLoading', true)
    commit('setDiagListFTPost', null)
    api.PostNreverse({ data: data }, message => {
      if (message.data == null) {
        commit('setDiagListFTPost', false)
      } else {
        if (message.data.code === 1) {
          commit('setDiagListFTPost', true)
        } else {
          commit('setDiagListFTPost', false)
        }
      }
      commit('setDiagListFTPostLoading', false)
    }, error => {
      commit('setDiagListFTPostLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  ResetState ({ commit }) {
    commit('resetstate')
  }

}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
