<template>
  <v-app>
    <router-view v-if="$auth.inProgress === 'none' && !groupLoading "  />
    <SplashScreen v-else />
  </v-app>
</template>

<script>
import SplashScreen from '@/components/splash/SplashContainer'

export default {
  name: 'App',
  components: {
    SplashScreen
  },
  computed: {
    groupLoading () {
      return this.$store.state.User.groupLoading
    }
  }
}
</script>
