<template>
    <v-card>
        <v-card-title>เลือกสถานที่ปฏิบัติงาน</v-card-title>
        <v-card-text><v-select v-model="selected" :items="group_list" item-text="group_describe" item-value="group_id">
        </v-select></v-card-text>
        <v-card-actions><v-spacer/> <v-btn color="primary" @click="saveGroup" :loading="posting" > บันทึก </v-btn></v-card-actions>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      selected: null,
      posting: false
    }
  },
  mounted () {
    this.selected = this.group.group_id
  },
  computed: {
    ...mapState('User', ['group', 'group_list'])
  },
  methods: {
    saveGroup () {
      this.$store.dispatch('User/SET_FIRST_TIME', false)
      if (this.group.group_id === this.selected) {
        this.$emit('onClose')
      } else {
        this.posting = true
        this.$store.dispatch('User/GET_GROUP', this.selected).then(() => {
          this.posting = false
          this.$emit('onClose')
        }).catch(() => {
          this.posting = false
        })
      }
    }
  },
}
</script>

<style>

</style>