import * as api from '../../api'
import router from '../../router'

const getDefaultState = () => {
  return {
    labelList: { data: [], loading: false, status: null },
    lbCompletedL: { data: [], loading: false, status: null },
    lbUncompletedL: { data: [], loading: false, status: null },
    labelInfo: { data: [], loading: false, status: null, post: null },
    autoPrint: false
  }
}
const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setAutoPrint (state, data) {
    state.autoPrint = data
  },
  setLabelList (state, data) {
    state.labelList.data = data
  },
  setLabelListLoading (state, data) {
    state.labelList.loading = data
  },
  setLabelListStatus (state, data) {
    state.labelList.status = data
  },
  setLbInfoLoading (state, data) {
    state.labelInfo.loading = data
  },
  setLabelInfo (state, data) {
    if (data) {
      if (data.length > 0) {
        let location = data[0].leader
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < location.length; j++) {
            if (location[j].toLowerCase().indexOf(data[i].Generic_name.toLowerCase()) !== -1) {
              data[i].shelf_code = location[i]
              // console.log(location[i])
              break
            }
          }
        }
        state.labelInfo.data = data
      } else {
        state.labelInfo.data = data
      }
    }
  },
  setLabelInfoPost (state, data) {
    state.labelInfo.post = data
  },
  setLabelInfoLoading (state, data) {
    state.labelInfo.loading = data
  },
  setLabelInfoStatus (state, data) {
    state.labelInfo.status = data
  },
  setlbCompletedL (state, data) {
    state.lbCompletedL.data = data
  },
  setlbCompletedLLoading (state, data) {
    state.lbCompletedL.loading = data
  },
  setlbCompletedLStatus (state, data) {
    state.lbCompletedL.status = data
  },
  setlbUncompletedL (state, data) {
    data.forEach(element => {
      element.loading = false
      element.canceling = false
      element.disabled = false
    })
    state.lbUncompletedL.data = data
  },
  setlbUncompletedLLoading (state, data) {
    state.lbUncompletedL.loading = data
  },
  setlbUncompletedLStatus (state, data) {
    state.lbUncompletedL.status = data
  },
  setUpdateReceivedDx (state, data) {
    for (var i = 0; i < data.length; i++) {
      data[i].order_status = 3
    }
    // console.log(data)
    state.labelInfo.data = data
  }
}

const actions = {
  SetAutoPrint ({ commit }, data) {
    commit('setAutoPrint', data)
  },
  GetLabelFromGroupStock ({ commit, rootState }, datetime) {
    commit('setLabelListLoading', true)
    commit('setLabelListStatus', null)
    api.GetLabelFromGroupStock({ datetime: datetime, groupstock: rootState.User.group.group_id, status: 3, userId: rootState.User.user.extension_SystemID }, message => {
      if (message.data == null) {
        commit('setLabelListStatus', false)
        commit('setLabelList', [])
      } else {
        commit('setLabelListStatus', true)
        commit('setLabelList', message.data)
      }
      commit('setLabelListLoading', false)
    }, error => {
      commit('setLabelListLoading', false)
      console.log(error)
    })
  },
  GetLabelForAutomaticPrint ({ commit, rootState }, datetime) {
    commit('setLabelListLoading', false)
    commit('setLabelInfoStatus', null)
    commit('setLabelInfo', [])
    api.GetLabelFromGroupStock({ datetime: datetime, groupstock: rootState.User.group.group_id, status: 'A', userId: rootState.User.user.extension_SystemID }, message => {
      console.log(message)
      commit('setLabelInfo', message.data)
      if (message.data == null) {
        commit('setLabelInfoStatus', false)
        commit('setLabelInfo', [])
      } else {
        console.log(message.data)
        commit('setLabelInfoStatus', true)
        commit('setLabelInfo', message.data)
      }
      commit('setLabelListLoading', false)
    }, error => {
      commit('setLabelListLoading', false)
      console.log(error)
    })
  },
  GetCompletedLabelFromGroupStock ({ commit, rootState }, datetime) {
    commit('setlbCompletedLLoading', true)
    commit('setlbCompletedLStatus', null)
    api.GetLabelFromGroupStock({ datetime: datetime, groupstock: rootState.User.group.group_id, status: 4, userId: rootState.User.user.extension_SystemID }, message => {
      if (message.data == null) {
        commit('setlbCompletedLStatus', false)
        commit('setlbCompletedL', [])
      } else {
        commit('setlbCompletedLStatus', true)
        commit('setlbCompletedL', message.data)
      }
      commit('setlbCompletedLLoading', false)
    }, error => {
      commit('setlbCompletedLLoading', false)
      console.log(error)
    })
  },
  GetUncompletedLabelFromGroupStock ({ commit, rootState }, datetime) {
    commit('setlbUncompletedLLoading', true)
    commit('setlbUncompletedLStatus', null)
    api.GetLabelFromGroupStock({ datetime: datetime, groupstock: rootState.User.group.group_id, status: 6, userId: rootState.User.user.extension_SystemID }, message => {
      if (message.data == null) {
        commit('setlbUncompletedLStatus', false)
        commit('setlbUncompletedL', [])
      } else {
        commit('setlbUncompletedLStatus', true)
        commit('setlbUncompletedL', message.data)
      }
      commit('setlbUncompletedLLoading', false)
    }, error => {
      commit('setlbUncompletedLLoading', false)
      console.log(error)
    })
  },
  ResetState ({ commit }) {
    commit('resetstate')
  },
  GetLabelFromVisitId ({ rootState, commit }, { visitId, mode }) {
    commit('setLabelInfoLoading', true)
    // commit('setLabelInfoStatus', null)
    commit('setLabelInfo', [])
    api.GetLabelFromVisitId({ visitId: visitId, token: rootState.authen.token, groupstock: rootState.User.group.group_id, mode: mode, userId: rootState.User.user.extension_SystemID }, message => {
      commit('setLabelInfo', message.data)
      if (message.data == null) {
        // commit('setLabelInfoStatus', false)
        commit('setLabelInfo', [])
      } else {
        // commit('setLabelInfoStatus', true)
        commit('setLabelInfo', message.data)
      }
      commit('setLabelInfoLoading', false)
    }, error => {
      commit('setLabelInfoLoading', false)
      console.log(error)
    })
  },
  PrintLabelFromVisitId ({ rootState, commit }, { visitId, mode }) {
    commit('setLabelInfoLoading', true)
    commit('setLabelInfoStatus', null)
    commit('setLabelInfo', [])
    api.GetLabelFromVisitId({ visitId: visitId, groupstock: rootState.User.group.group_id, mode: mode, userId: rootState.User.user.extension_SystemID }, message => {
      commit('setLabelInfo', message.data)
      if (message.data == null) {
        commit('setLabelInfoStatus', false)
        commit('setLabelInfo', [])
      } else {
        commit('setLabelInfoStatus', true)
        commit('setLabelInfo', message.data)
      }
      commit('setLabelInfoLoading', false)
    }, error => {
      commit('setLabelInfoLoading', false)
      console.log(error)
    })
  },
  PostLabel ({ rootState, commit, state }, { data }) {
    commit('setLabelListLoading', true)
    commit('setLabelInfoPost', null)
    api.PostLabel({ labelList: data }, () => {
      commit('setLabelInfoPost', true)
      // commit('setLabelInfo', message.data)
      commit('setLabelListLoading', false)
    }, error => {
      commit('setLabelInfoPost', false)
      commit('setLabelListLoading', false)
      console.log(error)
    })
  },
  ReceivedDx ({ rootState, commit, state }, visitId) {
    commit('setLabelListLoading', true)
    commit('setLabelInfoPost', null)
    commit('setLabelInfo', [])
    api.GetLabelFromVisitId({ visitId: visitId, groupstock: rootState.User.group.group_id, mode: 'T', userId: rootState.User.user.extension_SystemID }, message => {
      commit('setLabelInfo', message.data)
      // console.log(message)
      if (message.data == null) {
        commit('setLabelInfoPost', false)
        commit('setLabelInfo', [])
        commit('setLabelListLoading', false)
      } else {
        for (let i = 0; i < message.data.length; i++) {
          message.data[i].group_stock = rootState.authen.groupStock
        }
        commit('setUpdateReceivedDx', message.data)
        api.PostLabel({ labelList: state.labelInfo.data }, message => {
          commit('setLabelInfoPost', true)
          // commit('setLabelInfo', message.data)
          commit('setLabelListLoading', false)
        }, error => {
          commit('setLabelListLoading', false)
          console.log(error)
        })
      }
    }, error => {
      commit('setLabelListLoading', false)
      console.log(error)
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
