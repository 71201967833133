<template>
  <v-card>
    <v-card-title>แจ้งเตือนรายการยาและเวชภัณฑ์<v-spacer/></v-card-title>
    <v-card-text>
    <v-data-table :headers="headers" :items="items" dense>
    <template v-slot:item.reason="{item}">
        <p v-if="item.reason===0">ไม่แจ้งเหตุผล</p>
         <p v-else-if="item.reason===1">ของกำลังจะหมด/ของหมดแล้ว</p>
        <p v-else>ของหมดคลัง/ของหมออายุ</p>
     </template>
    </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    items: {
      type: Array
    }
  },
  computed: {
    ...mapState('User', ['group'])
  },
  data () {
    return {
      headers: [
        { text: 'รหัส', value: 'Matcode' },
        { text: 'ชื่อรายการ', value: 'Matdesc' },
        { text: 'หมายเหตุ', value: 'reason' }
      ],
    }
  },
}
</script>

<style>

</style>