<template>
  <div>
    <!-- <D95Banner/> -->
    <AppBar @openDrawer="drawer = true"/>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
    >
      <v-list-item>
        <v-list-item-content>
         <v-list-item-title class="text-subtitle-2 font-weight-bold">ระบบบริหารจัดการสถานพยาบาล</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
      <v-list-item-group v-model="navmodel">
        <v-list-item
          v-for="(item,index) in navItems"
          :key="index"
          link
          :to="{ name: item.navigate}"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from './AppBar'
export default {
  components: {
    AppBar,
    // D95Banner: () => import('@/components/gdpr/D95Banner') 
  },
  computed: {
    ...mapState('User', ['navItems'])
  },
  created () {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  methods: {
    showRefreshUI (e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail
      this.snackBtnText = 'Refresh'
      this.snackWithBtnText = 'New version available!'
      this.snackWithButtons = true
    },
    refreshApp () {
      this.snackWithButtons = false
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return }
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    onImgError () {
      this.pat_img = require('@/assets/avatar.png')
    }
  },
  data () {
    return {
      drawer: false,
      navmodel: {}
    }
  }
}
</script>
<style lang="scss" scoped>
.v-application a {
    color: #2f2f88;
}
</style>
