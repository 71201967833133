import * as api from '../../api'
import router from '../../router'

const getDefaultState = () => {
  return {
    reportData: { data: { headers: [], items: [], footer: null }, status: false, loading: false }
  }
}

const state = getDefaultState()
const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setReportData (state, data) {
    if (data == null) {
      state.reportData.data = { headers: [], items: [], footer: null }
    } else {
      var tempHeader = []
      var tempItem = []
      if (data.report_head != null) {
        for (var i = 0; i < data.report_head.length; i++) {
          tempHeader.push({ text: data.report_head[i], value: 'col' + i })
        }
        for (var j = 0; j < data.report_body.length; j++) {
          var jsonItem = {}
          for (var k = 0; k < data.report_body[j].length; k++) {
            jsonItem['col' + k] = data.report_body[j][k]
          }
          tempItem.push(jsonItem)
        }
        console.log(data.report_id)
        if (Number(data.report_id) === 13) {
          try {
            tempItem.forEach(element => {
              let _d = element.col3.split('/')
              element.date = new Date(_d[2] + '-' + _d[1] + '-' + _d[0])
              element.col3 = new Date(_d[2] + '-' + _d[1] + '-' + _d[0]).toLocaleDateString('th-th')
            })
          } catch (err) {

          }
        }
        console.log(tempItem)

        state.reportData.data.headers = tempHeader
        state.reportData.data.items = tempItem
      } else {
        state.reportData.data = { headers: [], items: [], footer: null }
      }
    }
  },
  setReportDataLoading (state, data) {
    state.reportData.loading = data
  },
  setReportDataStatus (state, data) {
    state.reportData.status = data
  }
}
const actions = {
  initialReportData ({ commit }) {
    commit('setReportData', null)
  },
  getReportData ({ rootState, commit }, payload) {
    commit('setReportDataLoading', true)
    commit('setReportDataStatus', false)
    api.GetReport({ userId: payload.userId, reportVal: payload.reportVal, groupId: payload.groupId, sd: payload.sd, ed: payload.ed }, message => {
      if (message.data == null) {
        commit('setReportData', null)
      } else {
        commit('setReportData', message.data[0])
      }
      commit('setReportDataLoading', false)
      commit('setReportDataStatus', true)
    }, error => {
      commit('setReportDataLoading', false)
      commit('setReportDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
