import * as api from '../../api'
import router from '../../router'
const getDefaultState = () => {
  return {
    docList: { data: [], loading: false, status: null },
    docType: { data: [], loading: false, status: null },
    docData: { data: [], loading: false, status: null, post: null, page: {} },
    myDocuments: []
  }
}
const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setDocList (state, data) {
    data.records.forEach((element) => {
      element.loading = false
      element.disabled = false
    })
    state.docList.data = data
  },
  setDocListLoading (state, data) {
    state.docList.loading = data
  },
  setDocListStatus (state, data) {
    state.docList.status = data
  },
  setDocType (state, data) {
    state.docType.data = data
  },
  setDocTypeLoading (state, data) {
    state.docType.loading = data
  },
  setDocTypeStatus (state, data) {
    state.docType.status = data
  },
  setDocData (state, data) {
    state.docData.data = data
    // console.log(data)
    if (!(Object.entries(data).length === 0 && data.constructor === Object)) {
      let jsonformat = {}
      for (let i = 0; i < data.records[0].page_ele.length; i++) {
        jsonformat[data.records[0].page_ele[i].ele_name] = (data.records[0].page_ele[i].ele_val === null) ? '' : data.records[0].page_ele[i].ele_val
      }
      // console.log(jsonformat)
      state.docData.page = jsonformat
    } else {
      state.docData.page = {}
    }
  },
  setDocDataLoading (state, data) {
    state.docData.loading = data
  },
  setDocDataStatus (state, data) {
    state.docData.status = data
  },
  setDocDataPost (state, data) {
    state.docData.post = data
  },
  SET_MY_DOCUMENT (state, { item, rid }) {
    item.forEach(element => {
      element.loading = false
      element.disable = false
      element.rid = rid
    })
    state.myDocuments = item
  },
  SET_MY_DOCUMENT_LOADING (state, { item, loading }) {
    state.myDocuments[state.myDocuments.indexOf(item)].loading = loading
  }
}

const actions = {
  ResetBed ({ commit }) {
    commit('resetstate')
  },
  GetDocType ({ rootState, commit }) {
    commit('setDocTypeLoading', true)
    commit('setDocTypeStatus', null)
    commit('setDocType', [])
    api.GetDocByRid({ rid: 0, rec: 0, groupId: rootState.User.group.group_id }, message => {
      if (message.data !== null) {
        commit('setDocTypeStatus', true)
        // console.log(message.data)
        commit('setDocType', message.data)
      } else {
        commit('setDocTypeStatus', false)
      }
      commit('setDocTypeLoading', false)
    }, error => {
      console.log(error)
      commit('setDocTypeStatus', false)
      commit('setDocTypeLoading', false)
      if (error.response.status === 401) {

      }
    })
  },
  GetDocList ({ rootState, commit }, { rid, rec }) {
    commit('setDocListLoading', true)
    api.GetDocByRid({ rid: rid, rec: rec, groupId: rootState.User.group.group_id }, message => {
      if (message.data !== null) {
        commit('setDocListStatus', true)
        commit('setDocList', message.data[0])
      } else {
        commit('setDocList', [])
      //  commit('setDocListStatus', false)
      }
      commit('setDocListLoading', false)
    }, error => {
      console.log(error)
      commit('setDocListStatus', false)
      commit('setDocListLoading', false)
    })
  },
  GET_DOC_TEMPLATE_WITH_DATA ({ rootState, commit }, item) {
    commit('SET_MY_DOCUMENT_LOADING', { item: item, loading: true })
    return new Promise((resolve, reject) => {
      api.GetDocByRid({ rid: item.rid, rec: item.rec_id, groupId: rootState.User.group.group_id }, message => {
        commit('SET_MY_DOCUMENT_LOADING', { item: item, loading: false })
        if (message.data) {
          commit('setDocData', message.data[0])
          resolve()
        } else {
          reject(new Error('Null data'))
        }
      }, error => {
        commit('SET_MY_DOCUMENT_LOADING', { item: item, loading: false })
        reject(new Error(error))
      })
    })
  },
  GET_DOC_LIST_BY_RID ({ rootState, commit }, data) {
    return new Promise((resolve, reject) => {
      api.GetDocByRid({ rid: data.rid, rec: 0, groupId: rootState.User.group.group_id }, message => {
        if (message.data) {
          commit('SET_MY_DOCUMENT', { item: message.data[0].records, rid: data.rid })
          resolve()
        } else {
          reject(new Error('Null data'))
        }
      }, error => {
        reject(new Error(error))
      })
    })
  },
  GetDocData ({ rootState, commit }, { rid, rec }) {
    commit('setDocDataLoading', true)
    commit('setDocDataStatus', null)
    // commit('setDocData', {})
    api.GetDocByRid({ rid: rid, rec: rec, groupId: rootState.User.group.group_id }, message => {
      if (message.data !== null) {
        commit('setDocDataStatus', true)
        commit('setDocData', message.data[0])
      } else {
        commit('setDocDataStatus', false)
      }
      commit('setDocDataLoading', false)
    }, error => {
      console.log(error)
      commit('setDocDataStatus', false)
      commit('setDocDataLoading', false)
      if (error.response.status === 401) {

      }
    })
  },
  GetDocTemplateWithData ({ rootState, commit }, { rid, subclass, visit }) {
    commit('setDocDataLoading', true)
    commit('setDocDataStatus', null)
    commit('setDocData', {})
    api.GetDocTemplateWithData({ rid: rid, subclass: subclass, visit: visit, groupId: rootState.User.group.group_id }, message => {
      if (message.data !== null) {
        commit('setDocDataStatus', true)
        commit('setDocData', message.data[0])
      } else {
        commit('setDocDataStatus', false)
      }
      commit('setDocDataLoading', false)
    }, error => {
      console.log(error)
      commit('setDocDataStatus', false)
      commit('setDocDataLoading', false)
      if (error.response.status === 401) {

      }
    })
  },
  PostDoc ({ rootState, commit }, { data }) {
    commit('setDocDataLoading', true)
    commit('setDocDataPost', null)
    api.PostDoc({ data: data, token: rootState.authen.token }, message => {
      if (message.data.code === 1 || message.data.code === 2) {
        commit('setDocDataPost', true)
      } else {
        commit('setDocDataPost', false)
      }
      commit('setDocDataLoading', false)
    }, error => {
      commit('setDocDataLoading', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
