import * as api from '../../api'
import router from '../../router'

const getDefaultState = () => {
  return {
    myShiftsData: { data: [], count: 0, status: false, loading: false },
    leaveShiftsData: { data: [], count: 0, status: false, loading: false }
  }
}

const state = getDefaultState()
const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setMyShiftsData (state, data) {
    if (data !== null) {
      state.myShiftsData.data = data
    }
  },
  setMyShiftsDataLoading (state, data) {
    state.myShiftsData.loading = data
  },
  setMyShiftsDataStatus (state, data) {
    state.myShiftsData.status = data
  },
  setLeaveShiftsData (state, data) {
    if (data !== null) {
      let _requestList = []
      data.forEach(element => {
        if (element.ot_request === 'U') {
          element.key = element.ot_id + element.hu_code_id + element.ot_st
          element.local_ot_st = new Date(element.ot_st).toLocaleDateString(
            'th-th',
            {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
          )
          element._start_dt = element.start_dt.split(' ')[1]
          element._end_dt = element.end_dt.split(' ')[1]
          _requestList.push(element)
        }
      })
      state.leaveShiftsData.data = _requestList
      state.leaveShiftsData.count = _requestList.length
    }
  },
  setLeaveShiftsDataLoading (state, data) {
    state.leaveShiftsData.loading = data
  },
  setLeaveShiftsDataStatus (state, data) {
    state.leaveShiftsData.status = data
  }
}
const actions = {
  getMyShifts ({ rootState, commit }, payload) {
    commit('setMyShiftsDataLoading', true)
    commit('setMyShiftsDataStatus', false)
    api.GetShiftsByStatus({
      date: payload.date,
      groupList: payload.groupList,
      groupId: payload.groupId,
      userId: payload.userId,
      status: payload.status,
      token: rootState.authen.token
    }, message => {
      // console.log(message.data)
      if (message.data !== null) {
        commit('setMyShiftsData', message.data)
      }
      commit('setMyShiftsDataLoading', false)
      commit('setMyShiftsDataStatus', true)
    }, error => {
      commit('setMyShiftsDataLoading', false)
      commit('setMyShiftsDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  },
  getLeaveShifts ({ rootState, commit }, payload) {
    commit('setLeaveShiftsDataLoading', true)
    commit('setLeaveShiftsDataStatus', false)
    api.GetShifts({
      date: payload.date,
      groupList: payload.groupList,
      groupId: payload.groupId,
      userId: payload.userId
    }, message => {
      if (message.data !== null) {
        commit('setLeaveShiftsData', message.data)
      }
      commit('setLeaveShiftsDataLoading', false)
      commit('setLeaveShiftsDataStatus', true)
    }, error => {
      commit('setLeaveShiftsDataLoading', false)
      commit('setLeaveShiftsDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {

      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
