import { getUsers, changePassword, getUserAD, createUser,updateUser, updateUsername } from '@/api/func_app_api'

const state = () => ({

})

const mutations = {
  
}

const actions = {
  GET_MEMBER ({ commit }, param) {
    return new Promise((resolve, reject) => {
        getUsers({page_size: param.page_size, page_no: param.page_no,keyword: param.keyword, uuid: param.uuid}, message=> {
            resolve(message.data)
        }, error=> {
            reject(error)
        })
    })
  },
  GET_USER_AD ({ commit }, param) {
    return new Promise((resolve, reject) => {
        getUserAD({uuid: param.uuid}, message=> {
            resolve(message.data)
        }, error=> {
            reject(error)
        })
    })
  },
  CREATE_USER ({ commit }, data) {
    return new Promise((resolve, reject) => {
      createUser(data, message=> {
            resolve(message.data)
        }, error=> {
            reject(error)
        })
    })
  },
  UPDATE_USERNAME ({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateUsername(data, message=> {
            resolve(message.data)
        }, error=> {
            reject(error)
        })
    })
  },
  UPDATE_USER ({ commit }, param) {
    return new Promise((resolve, reject) => {
      updateUser({uuid: param.uuid, group_permit: param.group_permit, web8: param.web8, new_page_permit: param.new_page_permit, user_fullname: param.user_fullname, user_engname: param.user_engname, cert_id: param.cert_id,active: param.active}, message=> {
            resolve(message.data)
        }, error=> {
            reject(error)
        })
    })
  },
  CHANGE_PASSWORD({commit}, data) {
    return new Promise((resolve, reject)=> {
        changePassword(data, message => {
            resolve(message.data)
        },error=>{
            reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
