// import * as firebase from 'firebase'
import router from '../../router'
import * as api from '../../api'
import { event } from 'vue-analytics'
import Vue from 'vue'
// import { initChat, initCall } from '@/plugins/communication'

const getDefaultState = () => {
  return {
    currentRoute: null,
    currentNavigate: null,
    user: null,
    status: null,
    error: null,
    token: null,
    userAdProfile: null,
    userInformation: { data: null, status: false, loading: false },
    groupInformation: { data: null, status: false, loading: false },
    permissiondenied: false,
    firsttime: true,
    groupId: null,
    groupDesc: '',
    groupStock: null,
    group_describe: null,
    isAuthenticated: null,
    bookingGroup: '',
    pageList: [
      { title: 'Screening', icon: 'mdi-hospital-building', navigate: '/screening', page: 'Screening', order: 1, user_perm_page_id: ['H', 'C', 'G'], oldpage: ['observer.aspx', 'Thoth.aspx', 'Hapi.aspx'] },
      { title: 'Physician', icon: 'mdi-stethoscope', navigate: '/physician', page: 'Doctor', order: 2, user_perm_page_id: ['D'], oldpage: ['Nun.aspx'] },
      { title: 'Pharmacy', icon: 'mdi-pill', navigate: '/pharmacy', page: 'Screening', order: 3, user_perm_page_id: ['H', 'C'], oldpage: [''] },
      { title: 'Laboratory', icon: 'mdi-water-outline', navigate: '/laboratory', page: 'Laboratory', order: 4, user_perm_page_id: ['7', 'F'], oldpage: ['Nut.aspx', 'Geb.aspx'] },
      { title: 'Patient', icon: 'mdi-account-details', navigate: '/patient', page: 'Patient', order: 5, user_perm_page_id: ['4'], oldpage: ['Horus.aspx'] },
      { title: 'Inventory', icon: 'mdi-inbox-multiple', navigate: '/inventory', page: 'Stock', order: 6, user_perm_page_id: ['1'], oldpage: ['Bastet.aspx'] },
      { title: 'Sets', icon: 'mdi-package-variant-closed', navigate: '/bag', page: 'Okay', order: 7, user_perm_page_id: ['3'], oldpage: ['Seshat.aspx'] },
      { title: 'Shift', icon: 'mdi-account-supervisor', navigate: '/staff', page: 'Shift', order: 8, user_perm_page_id: ['B', '5'], oldpage: ['Isis.aspx', 'Bes.aspx'] },
      { title: 'Report', icon: 'mdi-file-chart', navigate: '/report', page: 'Report', order: 9, user_perm_page_id: ['2'], oldpage: ['Maat.aspx'] },
      { title: 'Tele-visit', icon: 'mdi-video', navigate: '/telemed', page: 'Screening', order: 10, user_perm_page_id: ['C'], oldpage: [''] },
      { title: 'My Profile', icon: 'mdi-account-box', navigate: '/profile', page: 'ALL', order: 14, user_perm_page_id: ['ALL'], oldpage: [''] },
      { title: 'Company Settings', icon: 'mdi-cogs', navigate: '/setting', page: 'Shift', order: 15, user_perm_page_id: ['9'], oldpage: ['Sobek.aspx'] },
      { title: 'Help + Support', icon: 'mdi-face-agent', navigate: '/support', page: 'ALL', order: 16, user_perm_page_id: ['ALL'], oldpage: [''] } ,
      { title: 'Administrator', icon: 'mdi-cancel', navigate: '/administrator', page: 'ALL', order: 18, user_perm_page_id: ['A'], oldpage: [''] }
    ],
    nav_item: []
  }
}

const state = getDefaultState()
const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setCurrentRoute (state, dat) {
    state.currentRoute = dat
    state.currentNavigate = state.nav_item.map(function (e) { return e.navigate }).indexOf(dat)
    // console.log('current nav')
    // console.log(state.currentNavigate)
  },
  setPermission (state, dat) {
    state.permissiondenied = dat
  },
  setFirstTime (state, dat) {
    state.firsttime = dat
  },
  setUser (state, payload) {
    state.user = payload
  },
  removeUser (state) {
    state.user = null
  },
  setStatus (state, payload) {
    state.status = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  setToken (state, payload) {
    state.token = payload
  },
  setUserAdProfile (state, payload) {
    state.userAdProfile = payload
  },
  setUserInformation (state, payload) {
    event('Sign-In Action', 'setUserInfo', 'state', payload)
    state.userInformation.data = payload
  },
  setUserInformationStatus (state, payload) {
    state.userInformation.status = payload
  },
  setUserInformationLoading (state, payload) {
    state.userInformation.loading = payload
  },
  setGroupInfo (state, payload) {
    state.groupInformation.data = payload
    state.bookingGroup = ''
    if (payload) {
      for (let i = 0; i < payload.user_can_book.length; i++) {
        state.bookingGroup += payload.user_can_book[i].user_group + '|'
      }
    }
  },
  setGroupInfoStatus (state, payload) {
    state.groupInformation.status = payload
  },
  setGroupInfoLoading (state, payload) {
    state.groupInformation.loading = payload
  },
  setisAuthenticated (state, payload) {
    state.isAuthenticated = payload
  },
  setGroupStock (state, payload) {
    state.groupStock = payload
  },
  setNavItem (state, payload) {
    state.nav_item = []
    for (let j = 0; j < state.pageList.length; j++) {
      for (let i = 0; i < payload.user_perm_pages.length; i++) {
        if (state.pageList[j].user_perm_page_id.indexOf(payload.user_perm_pages[i].user_perm_page_id) !== -1) {
          state.nav_item.push(state.pageList[j])
          break
        }
      }
    }

    for (let j = 0; j < state.pageList.length; j++) {
      if (state.pageList[j].user_perm_page_id.indexOf('ALL') !== -1) {
        state.nav_item.push(state.pageList[j])
      }
    }
    state.nav_item.sort(compare)
  },
  setGroupId (state, payload) {
    state.groupId = payload
    state.groupStock = payload
    // let group = [
    //   { text: 'Ramathibodi Health Care Frontier', value: 1 },
    //   { text: 'SCG บางซื่อ', value: 2 },
    //   { text: 'Toyota เกตเวย์', value: 3 },
    //   { text: 'ThaiPBS วิภาวดี', value: 4 },
    //   { text: 'Toyota บ้านโพธิ์', value: 5 },
    //   { text: 'Toyota สำโรง', value: 6 },
    //   { text: 'กระทรวงการต่างประเทศ พญาไท', value: 7 },
    //   { text: 'สยามโตโยต้า อมตะนคร', value: 8 },
    //   { text: 'SME Bank', value: 9 },
    //   { text: 'Thai YAMAHA MOTOR co,LTD.', value: 10 },
    //   { text: 'ธนาคารอาคารสงเคราะห์ (สนญ)', value: 11 },
    //   { text: 'การท่าเรือแห่งประเทศไทย', value: 12 },
    //   { text: 'AIMC อาคาร 1', value: 13 },
    //   { text: 'AIMC ตึกพระเทพ', value: 14 },
    //   { text: 'AIMC เรวดี', value: 15 },
    //   { text: 'Bangchak Co.,ltd', value: 16 },
    //   { text: 'บจก.เชลล์แห่งประเทศไทย', value: 17 },
    //   { text: 'บจก.อีซูซุมอเตอร์ ประเทศไทย', value: 18 },
    //   { text: 'การท่องเที่ยวแห่งประเทศไทย', value: 19 },
    //   { text: 'บริษัท ซีพี ออลล์ จำกัด (มหาชน)', value: 23 }
    // ]
    for (let i = 0; i < state.userInformation.data.group_permits.length; i++) {
      if (payload === state.userInformation.data.group_permits[i].group_id) {
        state.groupDesc = state.userInformation.data.group_permits[i].group_describe
      }
    }
  }

}
const actions = {
  SetPermission ({ commit }, data) {
    commit('setPermission', data)
  },
  getUserInformation ({ rootState, commit }, userId) {
    commit('setUserInformationLoading', true)
    api.GetUserInformation({ userId: userId, token: rootState.auth.token }, message => {
      if (message.data !== null) {
        // commit('setUserInformation', message.data[0])
        // commit('setUserInformationLoading', false)
        commit('setUserInformation', message.data[0])
        commit('setNavItem', message.data[0])
        commit('setUserInformationLoading', false)
        commit('setUserInformationStatus', true)
        commit('setisAuthenticated', true)
      }
    }, error => {
      commit('setUserInformationLoading', false)
      console.log(error)
    })
  },
  loadGroupInfo ({ commit }, groupId) {
    commit('setGroupId', groupId)
    commit('setGroupInfoLoading', true)
    commit('setGroupInfoStatus', null)
    api.GetGroupInfo({ groupId: groupId }, message => {
      if (message.data) {
        commit('setGroupInfo', message.data[0])
        // initChat({ groupId: message.data[0].group_id })
        // initCall({ groupId: message.data[0].group_id, groupDesc: message.data[0].groupDesc })
        commit('setGroupInfoStatus', true)
      } else {
        commit('setisAuthenticated', false)
        commit('setGroupInfoStatus', false)
        commit('setPermission', true)
      }
      commit('setGroupInfoLoading', false)
    }, error => {
      commit('setGroupInfoLoading', false)
      commit('setGroupInfoStatus', false)
      commit('setGroupInfoStatus', false)
      commit('setisAuthenticated', false)
      commit('setPermission', true)
      console.log(error)
    })
  },
  signOut ({ commit }) {
    commit('resetstate')
    commit('setisAuthenticated', false)
  },
  SetFirstTime ({ commit }, data) {
    commit('setFirstTime', data)
  },
  loadUserInfo ({ commit }) {
    if (Vue.prototype.$auth.claim.extension_SystemID) {
      api.GetUserInformation({ userId: Vue.prototype.$auth.claim.extension_SystemID }, message => {
        if (message.data) {
          commit('setUserInformation', message.data[0])
        }
      }, error => {
        console.log(error)
      })
    }
  },
  signInSuccess ({ commit, state }) {
    commit('setPermission', false)
    commit('setisAuthenticated', false)
    if (Vue.prototype.$auth.claim.extension_SystemID) {
      api.GetUserInformation({ userId: Vue.prototype.$auth.claim.extension_SystemID }, message => {
        if (message.data) {
          if (message.data[0].group_permits && message.data[0].user_perm_pages) {
            let userData = message.data[0]
            commit('setisAuthenticated', true)
            // console.log(Vue.prototype.$auth.user.uniqueId)
            Vue.prototype.$ga.set('userId', Vue.prototype.$auth.user.uniqueId)
            // commit('setUserAdProfile', Vue.prototype.$msal.data.user)
            commit('setUserInformation', message.data[0])
            commit('setNavItem', message.data[0])
            let groupId
            if (state.groupId) {
              for (let i = 0; i < message.data[0].group_permits.length; i++) {
                if (message.data[0].group_permits[i].group_id === state.groupId || message.data[0].group_permits[i].group_id === 1) {
                  groupId = state.groupId
                  break
                } else {
                  if (message.data[0].group_id) {
                    commit('setGroupId', message.data[0].group_id)
                    groupId = message.data[0].group_id
                  } else {
                    commit('setGroupId', message.data[0].group_permits[0].group_id)
                    groupId = message.data[0].group_permits[0].group_id
                  }
                }
              }
            } else if (message.data[0].current_group_id > 0) {
              let hasPermit = message.data[0].group_permits.filter(el => el.group_id === message.data[0].current_group_id)
              if (hasPermit.length > 0) {
                commit('setGroupId', message.data[0].current_group_id)
                groupId = message.data[0].current_group_id
              } else {
                commit('setGroupId', message.data[0].group_permits[0].group_id)
                groupId = message.data[0].group_permits[0].group_id
              }
            } else {
              commit('setGroupId', message.data[0].group_permits[0].group_id)
              groupId = message.data[0].group_permits[0].group_id
            }

            api.GetGroupInfo({ groupId: groupId }, message => {
              if (message.data) {
                if (!state.currentRoute) {
                  let firstNavigate = state.pageList.filter(function (el) {
                    return userData.user_perm_pages.filter(val => el.user_perm_page_id.indexOf(val.user_perm_page_id) !== -1).length > 0 && (el.oldpage.indexOf(userData.user_firstpage) !== -1)
                  })
                  // check page permit
                  if (firstNavigate.length === 0) {
                    firstNavigate = state.pageList.filter(function (el) {
                      return userData.user_perm_pages.filter(val => el.user_perm_page_id.indexOf(val.user_perm_page_id) !== -1)
                    })
                  }
                  if (firstNavigate.length !== 0) {
                    commit('setGroupInfo', message.data[0])
                    commit('setisAuthenticated', true)
                    router.push(firstNavigate[0].navigate, () => {})
                  } else {
                    commit('setisAuthenticated', false)
                    commit('setPermission', true)
                  }
                } else {
                  // check page permit
                  let firstNavigate = state.pageList.filter(function (el) {
                    return userData.user_perm_pages.filter(val => el.user_perm_page_id.indexOf(val.user_perm_page_id) !== -1)
                  })

                  firstNavigate = firstNavigate.filter(function (el) {
                    return el.navigate === state.currentRoute
                  })

                  // check page permit
                  if (firstNavigate.length === 0) {
                    firstNavigate = state.pageList.filter(function (el) {
                      return userData.user_perm_pages.filter(val => el.user_perm_page_id.indexOf(val.user_perm_page_id) !== -1)
                    })
                  }

                  if (firstNavigate.length !== 0) {
                    commit('setGroupInfo', message.data[0])
                    commit('setisAuthenticated', true)
                    router.push(firstNavigate[0].navigate, () => {})
                  } else {
                    commit('setisAuthenticated', false)
                    commit('setPermission', true)
                  }
                }
              } else {
                commit('setisAuthenticated', false)
                commit('setPermission', true)
                router.push('/', () => {})
              }
            }, error => {
              console.log(error)
              Vue.prototype.$auth.logout()
              // Vue.prototype.$msal.signOut()
            })
          } else {
            commit('setisAuthenticated', false)
            commit('setPermission', true)
          }
        } else {
          // User ID not found ติดต่อผู้ดูแลระบบเพื่อกำหนดสิทธิ์การเข้าใช้งาน
          // Vue.prototype.$msal.signOut()
          commit('setisAuthenticated', false)
          commit('setPermission', true)
        }
      }, error => {
        console.log(error)
        Vue.prototype.$auth.logout()
      })
    } else {
      commit('setisAuthenticated', false)
      commit('setPermission', true)
    }
  },
  /*   signInSuccess ({ commit, state }) {
    commit('setPermission', false)
    commit('setisAuthenticated', false)
    if (Vue.prototype.$msal.data.user.idToken.extension_SystemID) {
      api.GetUserInformation({ userId: Vue.prototype.$msal.data.user.idToken.extension_SystemID }, message => {
        if (message.data) {
          if (message.data[0].group_permits && message.data[0].user_perm_pages) {
            let userData = message.data[0]
            commit('setisAuthenticated', true)
            commit('setUserAdProfile', Vue.prototype.$msal.data.user)
            commit('setUserInformation', message.data[0])
            commit('setNavItem', message.data[0])
            let groupId
            if (state.groupId) {
              for (let i = 0; i < message.data[0].group_permits.length; i++) {
                if (message.data[0].group_permits[i].group_id === state.groupId || message.data[0].group_permits[i].group_id === 1) {
                  groupId = state.groupId
                  break
                } else {
                  if (message.data[0].group_id) {
                    commit('setGroupId', message.data[0].group_id)
                    groupId = message.data[0].group_id
                  } else {
                    commit('setGroupId', message.data[0].group_permits[0].group_id)
                    groupId = message.data[0].group_permits[0].group_id
                  }
                }
              }
            }else if(message.data[0].current_group_id > 0){
              let hasPermit = message.data[0].group_permits.filter(el=>el.group_id === message.data[0].current_group_id)
              if(hasPermit.length>0){
              commit('setGroupId', message.data[0].current_group_id)
              groupId = message.data[0].current_group_id
              }else{
              commit('setGroupId', message.data[0].group_permits[0].group_id)
              groupId = message.data[0].group_permits[0].group_id
              }
            } else {
              commit('setGroupId', message.data[0].group_permits[0].group_id)
              groupId = message.data[0].group_permits[0].group_id
            }

            api.GetGroupInfo({ groupId: groupId }, message => {
              if (message.data) {
                if(!state.currentRoute){
                 //check first page permission
                let firstNavigate = state.pageList.filter(function (el) {
                  return userData.user_perm_pages.filter(val=> val.user_perm_page_id=== el.user_perm_page_id ) && (el.oldpage.indexOf(userData.user_firstpage) !== -1)})
                //check page permit
                if(firstNavigate.length===0){
                  firstNavigate = state.pageList.filter(function (el) {
                    return userData.user_perm_pages.filter(val=> val.user_perm_page_id=== el.user_perm_page_id )})
                }

                if(firstNavigate.length !==0){
                  commit('setGroupInfo', message.data[0])
                  commit('setisAuthenticated', true)
                  router.push(firstNavigate[0].navigate, () => {})
                }else{
                  commit('setisAuthenticated', false)
                  commit('setPermission', true)
                }

              }else{
                   //check page permit
                  let firstNavigate = state.pageList.filter(function (el) {
                      return userData.user_perm_pages.filter(val=> val.user_perm_page_id=== el.user_perm_page_id )})

                  firstNavigate = firstNavigate.filter(function (el) {
                    return el.navigate === state.currentRoute
                  })

                  //check page permit
                  if(firstNavigate.length===0){
                      firstNavigate = state.pageList.filter(function (el) {
                    return userData.user_perm_pages.filter(val=> val.user_perm_page_id=== el.user_perm_page_id )})
                  }

                  if(firstNavigate.length !==0){
                    commit('setGroupInfo', message.data[0])
                    commit('setisAuthenticated', true)
                    router.push(firstNavigate[0].navigate, () => {})
                  }else{
                    commit('setisAuthenticated', false)
                    commit('setPermission', true)
                  }
              }

              } else {
                commit('setisAuthenticated', false)
                commit('setPermission', true)
                router.push('/', () => {})
              }
            }, error => {
              console.log(error)
              Vue.prototype.$msal.signOut()
            })
          } else {
            commit('setisAuthenticated', false)
            commit('setPermission', true)
          }
        } else {
          // User ID not found ติดต่อผู้ดูแลระบบเพื่อกำหนดสิทธิ์การเข้าใช้งาน
          // Vue.prototype.$msal.signOut()
          commit('setisAuthenticated', false)
          commit('setPermission', true)
        }
      }, error => {
        console.log(error)
        Vue.prototype.$msal.signOut()
      })
    } else {
      commit('setisAuthenticated', false)
      commit('setPermission', true)
    }
  }, */
  SetCurrentRoute ({ commit }, data) {
    commit('setCurrentRoute', data)
  },
  setGroupStock ({ commit }, data) {
    commit('setGroupStock', data)
  },
  logOut ({ commit }) {
    commit('resetstate')
  },
  SetisAuthenticated ({ commit }, payload) {
    commit('setisAuthenticated', payload)
  }
}

const getters = {

}

function compare (a, b) {
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
