import * as api from '../../api'

const getDefaultState = () => {
  return {
    allergyList: { data: [], loading: false, status: null, post: null, postloading: false }
  }
}
const state = getDefaultState()

const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  setAllergyList (state, data) {
    state.allergyList.data = data
  },
  setAllergyLoading (state, data) {
    state.allergyList.loading = data
  },
  setAllergyStatus (state, data) {
    state.allergyList.status = data
  },
  setAllergyPost (state, data) {
    state.allergyList.post = data
  },
  setAllergyPostLoading (state, data) {
    state.allergyList.postloading = data
  }
}

const actions = {
  ResetAllergy ({ commit }) {
    commit('resetstate')
  },
  GetAllergyFromKeyword ({ rootState, commit }, keyword) {
    commit('setAllergyLoading', true)
    commit('setAllergyStatus', null)
    api.GetAllergy({ keyword: keyword, token: rootState.authen.token }, message => {
      // console.log(message.data)
      if (message.data == null) {
        commit('setAllergyStatus', false)
        commit('setAllergyList', [])
      } else {
        commit('setAllergyStatus', true)
        commit('setAllergyList', message.data)
      }
      commit('setAllergyLoading', false)
    }, error => {
      commit('setAllergyLoading', false)
      console.log(error)
    })
  },
  POST_ALLERGY_BY_NURSE ({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      api.PostAllergy({ data: data }, message => {
        if (message.data) {
          dispatch('patient/GET_PATIENT_BY_ID', data.record_id, { root: true }).then(() => {
            resolve(message.data)
          }, error => {
            reject(error)
          })
        } else {
          reject(message)
        }
      }, error => {
        console.log(error)
        reject(error)
      })
    })
  },
  POST_ALLERGY_BY_PHYSICIAN ({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      api.PostAllergy({ data: data }, message => {
        if (message.data) {
          // dispatch('patient/GetPatientById', data.record_id, { root: true })
          resolve(message.data)
        } else {
          reject(message)
        }
      }, error => {
        console.log(error)
        reject(error)
      })
    })
  },
  PostAllergy ({ rootState, commit }, data) {
    commit('setAllergyPostLoading', true)
    commit('setAllergyPost', null)
    api.PostAllergy({ data: data, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setAllergyPost', false)
      } else {
        if (message.data.code === 1) {
          commit('setAllergyPost', true)
        } else {
          commit('setAllergyPost', false)
        }
      }
      commit('setAllergyPostLoading', false)
    }, error => {
      commit('setAllergyPostLoading', false)
      console.log(error)
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
