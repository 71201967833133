import * as api from '../../api'
import { getStockItemsTransac } from '../../api/func_app_api'
import router from '../../router'
import Vue from 'vue'

const getDefaultState = () => {
  return {
    restockData: { data: [], status: false, loading: false },
    stockData: { data: [], status: false, loading: false },
    orderedData: { data: [], status: false, loading: false },
    itemsData: { data: [], status: false, loading: false },
    restockResult: { code: null, message: null, status: false, loading: false },
    stockResult: { code: null, message: null, status: false, loading: false },
    orderedResult: { code: null, message: null, status: false, loading: false },
    itemList: { data: null, loading: false, status: false },
    expiredData: { data: [], status: false, loading: false },
    stockItemTransac: []
  }
}

const state = getDefaultState()
const mutations = {
  resetstate (state) {
    Object.assign(state, getDefaultState())
  },
  SET_STOCK_ITEM_TRANSAC (state, data) {
    state.stockItemTransac = data
  },
  setRestockData (state, data) {
    if (data == null) { state.restockData.data = [] } else {
      let arrRestock = []
      arrRestock = data
      for (let i = 0; i < arrRestock.length; i++) {
        arrRestock[i].total_price = arrRestock[i].show_price * arrRestock[i].purchase
        arrRestock[i].total_price = Number(+arrRestock[i].total_price.toFixed(2))
        arrRestock[i].show_price = Number(+arrRestock[i].show_price.toFixed(2))
        if (arrRestock[i].reason == 0) {
          arrRestock[i].reason_name = 'ไม่แจ้งเหตุผล'
        } else if (arrRestock[i].reason == 1) { arrRestock[i].reason_name = 'ของกำลังจะหมด' } else if (arrRestock[i].reason == 2) { arrRestock[i].reason_name = 'ของหมดคลัง' }

        if (arrRestock[i].active == 'O') { arrRestock[i].mute = false } else if (arrRestock[i].active == 'T') { arrRestock[i].mute = true }
      }
      state.restockData.data = arrRestock
    }
  },
  setRestockDataLoading (state, data) {
    state.restockData.loading = data
  },
  setRestockDataStatus (state, data) {
    state.restockData.status = data
  },
  setStockData (state, data) {
    if (data === null) { state.stockData.data = [] } else {
      data.sort((a, b) => (a.exp_date > b.exp_date) ? 1 : ((b.exp_date > a.exp_date) ? -1 : 0))
      let arrTempData = data
      arrTempData.forEach(tempData => {
        tempData.display_exp_date = new Date(tempData.exp_date).toLocaleDateString('th-th')
        tempData.display_entrydate = new Date(tempData.entrydate).toLocaleDateString('th-th')
      })
      state.stockData.data = arrTempData
    }
  },
  setStockDataLoading (state, data) {
    state.stockData.loading = data
  },
  setStockDataStatus (state, data) {
    state.stockData.status = data
  },
  setOrderedData (state, data) {
    if (data === null) { state.orderedData.data = [] } else {
      data.sort((a, b) => (b.stock_id > a.stock_id) ? 1 : ((a.stock_id > b.stock_id) ? -1 : 0))
      let arrOrderedStock = data
      for (var i = 0; i < arrOrderedStock.length; i++) {
        arrOrderedStock[i].total_price = arrOrderedStock[i].buy_price * arrOrderedStock[i].remain
        arrOrderedStock[i].total_price = Number(+arrOrderedStock[i].total_price.toFixed(2))
        arrOrderedStock[i].buy_price = Number(+arrOrderedStock[i].buy_price.toFixed(2))
      }
      state.orderedData.data = arrOrderedStock
    }
  },
  setOrderedDataLoading (state, data) {
    state.orderedData.loading = data
  },
  setOrderedDataStatus (state, data) {
    state.orderedData.status = data
  },
  setItemsData (state, data) {
    if (data == null) { state.itemsData.data = [] } else { state.itemsData.data = data }
  },
  setItemsDataLoading (state, data) {
    state.itemsData.loading = data
  },
  setItemsDataStatus (state, data) {
    state.itemsData.status = data
  },
  setRestockResult (state, data) {
    state.restockResult.code = data.code
    state.restockResult.message = data.message
  },
  setRestockResultLoading (state, data) {
    state.restockResult.loading = data
  },
  setRestockResultStatus (state, data) {
    state.restockResult.status = data
  },
  setStockResult (state, data) {
    state.stockResult.code = data.code
    state.stockResult.message = data.message
  },
  setStockResultLoading (state, data) {
    state.stockResult.loading = data
  },
  setStockResultStatus (state, data) {
    state.stockResult.status = data
  },
  setOrderedResult (state, data) {
    state.orderedResult.code = data.code
    state.orderedResult.message = data.message
  },
  setOrderedResultLoading (state, data) {
    state.orderedResult.loading = data
  },
  setOrderedResultStatus (state, data) {
    state.orderedResult.status = data
  },
  setItemList (state, payload) {
    state.itemList.data = payload
  },
  setItemListLoading (state, payload) {
    state.itemList.loading = payload
  },
  setItemListStatus (state, payload) {
    state.itemList.status = payload
  },
  setExpiredData (state, data) {
    if (data == null) { state.expiredData.data = [] } else {
      data.sort((a, b) => (a.exp_date > b.exp_date) ? 1 : ((b.exp_date > a.exp_date) ? -1 : 0))
      let arrTempData = []
      for (var i = 0; i < data.length; i++) {
        let tempData = data[i]
        if (tempData.exp_date < new Date().toISOString().substr(0, 10)) {
          tempData.display_exp_date = new Date(tempData.exp_date).toLocaleDateString('th-th')
          tempData.display_entrydate = new Date(tempData.entrydate).toLocaleDateString('th-th')
          arrTempData.push(tempData)
        } else {
          break
        }
      }
      state.expiredData.data = arrTempData
    }
  },
  setExpiredDataLoading (state, data) {
    state.expiredData.loading = data
  },
  setExpiredDataStatus (state, data) {
    state.expiredData.status = data
  }
}
const actions = {
  GET_STOCK_ITEM_TRANSAC ({ commit }, param) {
    return new Promise((resolve, reject) => {
      getStockItemsTransac({ stock_id: param.stock_id }, message => {
        if (message.data) {
          message.data.result.sort((a, b) => {
            if (Vue.prototype.$dayjs(a.timestamp).valueOf() > Vue.prototype.$dayjs(b.timestamp).valueOf()) {
              return -1
            } else {
              return 1
            }
          })
          commit('SET_STOCK_ITEM_TRANSAC', message.data.result)
          resolve(message.data)
        }
      }, error => {
        reject(error)
      })
    })
    // commit('getStockItemsTransac')
  },
  getRestockData ({ rootState, commit }, payload) {
    commit('setRestockDataLoading', true)
    commit('setRestockDataStatus', false)
    if (payload.matDesc == null || payload.matDesc == '') { payload.matDesc = '%' }
    api.GetRestock({ groupId: payload.groupId, groupStock: payload.groupStock, matGroup: payload.matGroup, active: payload.active, matDesc: payload.matDesc}, message => {
      if (message.data == null) {
        commit('setRestockData', null)
      } else {
        commit('setRestockData', message.data)
      }
      commit('setRestockDataLoading', false)
      commit('setRestockDataStatus', true)
    }, error => {
      commit('setRestockDataLoading', false)
      commit('setRestockDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login', () => { })
      }
    })
  },
  getStockData ({ rootState, commit }, payload) {
    commit('setStockDataLoading', true)
    commit('setStockDataStatus', false)
    if (payload.matDesc == null || payload.matDesc == '') { payload.matDesc = '%' }
    api.GetStock({ groupId: payload.groupId, groupStock: payload.groupStock, matGroup: payload.matGroup, active: payload.active, matDesc: payload.matDesc, rid: payload.rid, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setStockData', null)
      } else {
        commit('setStockData', message.data)
      }
      commit('setStockDataLoading', false)
      commit('setStockDataStatus', true)
    }, error => {
      commit('setStockDataLoading', false)
      commit('setStockDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login', () => { })
      }
    })
  },
  getOrderedData ({ rootState, commit }, payload) {
    commit('setOrderedDataLoading', true)
    commit('setOrderedDataStatus', false)
    if (payload.matDesc == null || payload.matDesc == '') { payload.matDesc = '%' }
    api.GetStock({ groupId: payload.groupId, groupStock: payload.groupStock, matGroup: payload.matGroup, active: payload.active, matDesc: payload.matDesc, rid: payload.rid }, message => {
      if (message.data == null) {
        commit('setOrderedData', null)
      } else {
        commit('setOrderedData', message.data)
      }
      commit('setOrderedDataLoading', false)
      commit('setOrderedDataStatus', true)
    }, error => {
      commit('setOrderedDataLoading', false)
      commit('setOrderedDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login', () => { })
      }
    })
  },
  getItemsData ({ rootState, commit }, payload) {
    commit('setItemsDataLoading', true)
    commit('setItemsDataStatus', false)
    api.GetItems({ rid: payload.rid, id: payload.id, filter: payload.filter, visitId: payload.visitId, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setItemsData', null)
      } else {
        commit('setItemsData', message.data)
      }
      commit('setItemsDataLoading', false)
      commit('setItemsDataStatus', true)
    }, error => {
      commit('setItemsDataLoading', false)
      commit('setItemsDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login', () => { })
      }
    })
  },
  postRestock ({ rootState, commit }, payload) {
    commit('setRestockResultLoading', true)
    commit('setRestockResultStatus', false)
    api.PostRestock({ body: payload }, message => {
      if (message.data == null) {
        commit('setRestockResult', null)
      } else {
        commit('setRestockResult', message.data)
      }
      commit('setRestockResultLoading', false)
      commit('setRestockResultStatus', true)
    }, error => {
      commit('setRestockResultLoading', false)
      commit('setRestockResultStatus', false)
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login', () => { })
      }
    })
  },
  postStock ({ rootState, commit }, payload) {
    commit('setStockResultLoading', true)
    commit('setStockResultStatus', false)
    api.PostStock({ body: payload, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setStockResult', null)
      } else {
        commit('setStockResult', message.data)
      }
      commit('setStockResultLoading', false)
      commit('setStockResultStatus', true)
    }, error => {
      commit('setStockResultLoading', false)
      commit('setStockResultStatus', false)
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login', () => { })
      }
    })
  },
  postOrdered ({ rootState, commit }, payload) {
    commit('setOrderedResultLoading', true)
    commit('setOrderedResultStatus', false)
    api.PostStock({ body: payload }, message => {
      if (message.data == null) {
        commit('setOrderedResult', null)
      } else {
        commit('setOrderedResult', message.data)
      }
      commit('setOrderedResultLoading', false)
      commit('setOrderedResultStatus', true)
    }, error => {
      commit('setOrderedResultLoading', false)
      commit('setOrderedResultStatus', false)
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login', () => { })
      }
    })
  },
  GetStockItemFromKeyword ({ rootState, commit }, { keyword, visitId, filter }) {
    commit('setItemListLoading', true)
    commit('setItemListStatus', null)
    commit('setItemList', [])
    api.GetStockItemFromKeyword({ keyword: keyword, visitId: visitId, filter: filter, token: rootState.authen.token }, message => {
      if (message.data == null) {
        commit('setItemListStatus', false)
        commit('setItemList', [])
      } else {
        commit('setItemListStatus', true)
        commit('setItemList', message.data)
      }
      commit('setItemListLoading', false)
    }, error => {
      commit('setItemListLoading', false)
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login', () => { })
      }
    })
  },
  getInitialItemsData ({ commit }) {
    commit('setItemsDataLoading', false)
    commit('setItemsDataStatus', false)
    commit('setItemsData', null)
  },
  ResetStockState ({ commit }) {
    commit('resetstate')
  },
  getExpiredData ({ rootState, commit }, payload) {
    commit('setExpiredDataLoading', true)
    commit('setExpiredDataStatus', false)
    if (payload.matDesc == null || payload.matDesc == '') { payload.matDesc = '%' }
    api.GetStock({ groupId: payload.groupId, groupStock: payload.groupStock, matGroup: payload.matGroup, active: payload.active, matDesc: payload.matDesc, rid: payload.rid }, message => {
      if (message.data == null) {
        commit('setExpiredData', null)
      } else {
        commit('setExpiredData', message.data)
      }
      commit('setExpiredDataLoading', false)
      commit('setExpiredDataStatus', true)
    }, error => {
      commit('setExpiredDataLoading', false)
      commit('setExpiredDataStatus', false)
      console.log(error)
      if (error.response.status === 401) {
        router.push('/login', () => { })
      }
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
