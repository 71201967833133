
const URL = process.env.VUE_APP_API_BASE_URL

export function GetUserInformation ({ userId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/user/GetByParameters?user_id=' + userId + '&user_group_list&keyword=&group_id=1'
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetPatientByCardId ({ cardId, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/patients/GetByParameters?group_id=' + groupId + '&patient_stage=X&limit_list=1&keyword=' + cardId + '&cardid=' + cardId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetPatientByKeyword ({ keyword, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/patients/GetByParameters?group_id=' + groupId + '&patient_stage=X&limit_list=10&keyword=' + keyword + '&cardid='
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetPatientById ({ id, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/patients/GetByParameters?group_id=' + groupId + '&patient_stage=X&limit_list=1&keyword=' + id + '&cardid='
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetQueingVisitByDate ({ datetime, groupId, userId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/visits/GetByParameters?rid=0&user_id=' + userId + '&per_status_list=0|1|2|R&per_date=' + datetime + '&visit_id=0&group_id=' + groupId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetWaitingVisitByDate ({ datetime, userId, status, groupId, token }, cb, errorCb) {
  // visits/GetByParameters?rid=0&user_id=30&per_status_list=5&per_date=2020-01-08&visit_id=0&group_id=9
  window.axios({
    method: 'get',
    url: URL + '/visits/GetByParameters?rid=0&user_id=' + userId + '&per_status_list=' + status + '&per_date=' + datetime + '&visit_id=0&group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetQueingVisitByRid ({ rid, groupId, token, userId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/visits/GetByParameters?rid=' + rid + '&user_id=' + userId + '&per_status_list=0|1&per_date=&visit_id=0&group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function UpdateVisitById ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/visits/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetShiftsInfoByDate ({ date, grouplist, groupid, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/shifts/GetByParameters?user_id=0&group_id=' + groupid + '&start_date=' + date + '&user_group_list=' + grouplist + '&ot_status='

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDrugFromDx ({ keyword, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/nquiz/GetByParameters?id=' + keyword

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostDx ({ dxList, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/nquiz',
    data: dxList
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetSuggestedDrugFromVisit ({ visitId, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/nresponse/GetByParameters?id=' + visitId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostDrug ({ drugList, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/nresponse',
    data: drugList
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetLabelFromGroupStock ({ datetime, groupstock, status, token, userId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/label/GetByParameters?group_stock=' + groupstock + '&user_id=' + userId + '&visit_id=0&order_id_list&per_status=' + status + '&transfer_dt=' + datetime

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetLabelFromVisitId ({ visitId, token, groupstock, mode, userId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/label/GetByParameters?group_stock=' + groupstock + '&user_id=' + userId + '&visit_id=' + visitId + '&order_id_list&per_status=' + mode + '&transfer_dt='
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetStockItemFromKeyword ({ keyword, visitId, filter, userId, userGroup }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/items/GetByParameters?visit_id=' + visitId + '&id=' + keyword + '&filter=' + filter + '&user_id=' + userId + '&user_group=' + userGroup
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostLabel ({ labelList, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/label',
    data: labelList
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDiagFromKeyword ({ keyword }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/diag/GetByParameters?visit_id=0&rid=0&cont=1&keyword=' + keyword
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDiagFromVisit ({ visitId, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/diag/GetByParameters?visit_id=' + visitId + '&rid=0&cont=1&keyword=%'

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostDiag ({ diagList }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/diag',
    data: diagList
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostOrder ({ order }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/orders',
    data: order
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetOrderByVisit ({ visitId, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/orders/' + visitId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetReport ({ userId, reportVal, groupId, sd, ed, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/report/GetByParameters?user_id=' + userId + '&report_val=' + reportVal + '&group_id=' + groupId + '&sd=' + sd + '&ed=' + ed

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetStock ({ groupId, groupStock, matGroup, active, matDesc, rid, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/stock/GetByParameters?group_id=' + groupId + '&group_stock=' + groupStock + '&matgroup=' + matGroup + '&active=' + active + '&Matdesc=' + matDesc + '&rid=' + rid

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostStock ({ body }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/stock/',
    data: body
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetItems ({ rid, id, filter, visitId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/items/GetByParameters?rid=' + rid + '&id=' + id + '&filter=' + filter + '&visit_id=' + visitId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetRestock ({ groupId, groupStock, matGroup, active, matDesc }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/restock/GetByParameters?group_id=' + groupId + '&group_stock=' + groupStock + '&matgroup=' + matGroup + '&active=' + active + '&Matdesc=' + matDesc

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostRestock ({ body }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/restock/',
    data: body
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetPatient ({ groupId, patientStage, limitList, cardId, keyword }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/patients/GetByParameters?group_id=' + groupId + '&patient_stage=' + patientStage + '&limit_list=' + limitList + '&cardid=' + cardId + '&keyword=' + keyword

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostPatient ({ body }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/patients/',
    data: body
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetInsurance ({ policyNo, rid, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/insurance/GeyByParameters?policy_no=' + policyNo + '&rid=' + rid + '&group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetAllergy ({ keyword }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/allergy/' + keyword

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetBag ({ groupId, setId, checkStart, userId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/bag/GeyByParameters?group_id=' + groupId + '&set_id=' + setId + '&check_start=' + checkStart + '&user_id=' + userId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostAllergy ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/allergy/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetApply ({ keyword }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/apply/' + keyword

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetSet ({ userId, groupId, usetId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/uset/GetByParameters?visit_id=0&uset_id=' + usetId + '&user_id=' + userId + '&rid=0&group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostSet ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/uset/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDrugListFromFT ({ rid, userId, lmp, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/nreverse/GetByParameters?rid=' + rid + '&node_list=&user_id=' + userId + '&lmp=' + lmp

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDiagFromFT ({ rid, userId, lmp, node, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/nreverse/GetByParameters?rid=' + rid + '&node_list=' + node + '&user_id=' + userId + '&lmp=' + lmp

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostBag ({ body, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/bag/',
    data: body
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostNreverse ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/nreverse/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDepartmentCode ({ groupId, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/box/GetById?group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetAvailableBed ({ groupid, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/bed?group_id=' + groupid + '&admitting=0'

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostBoxOrder ({ body, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/box/',
    data: body
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetAllBed ({ groupid, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/bed?group_id=' + groupid + '&admitting=1'

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetCreatedBed ({ groupid }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/bed?group_id=' + groupid + '&admitting=2'
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostBed ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/bed/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDocListByRid ({ rid, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/doc/GetByParameters?rid=' + rid + '&subclass_id=0&visit_id=0&rec_id=0&group_id=' + groupId + '&class_id=0'

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDocByRid ({ rid, rec, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/doc/GetByParameters?rid=' + rid + '&subclass_id=0&visit_id=0&rec_id=' + rec + '&group_id=' + groupId + '&class_id=0'

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetAllDocType ({ groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/doc/GetByParameters?rid=0&subclass_id=0&visit_id=0&rec_id=0&group_id=' + groupId + '&class_id=0'

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDocTemplateWithData ({ rid, subclass, visit, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/doc/GetByParameters?rid=' + rid + '&subclass_id=' + subclass + '&visit_id=' + visit + '&rec_id=0&group_id=' + groupId + '&class_id=0'

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostDoc ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/doc/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetGroupInfo ({ groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/group/GetByParameters?group_id=' + groupId + '&group_stock=0&group_stamp=0'
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetLabTest ({ rid, visitId, pon, testStatusList, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/test/GetByParameters?rid=' + rid + '&visit_id= ' + visitId + '&pon=' + pon + '&test_status_list=' + testStatusList + '&group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetLabOrderByRid ({ rid, status, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/test/?rid=' + rid + '&visit_id=0&pon=0&test_status_list=' + status + '&group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostLabTest ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/test/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetLabItemById ({ id, visitId, groupId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/lab/GetByParameters?id=' + id + '&sv_id=0&visit_id=' + visitId + '&group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostLabOrder ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/test/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostApply ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/apply/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetTeleVisitByDate ({ datetime, groupId, token, userId = 0 }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/visits/GetByParameters?rid=0&user_id=' + userId + '&per_status_list=2&per_date=' + datetime + '&visit_id=0&group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetConsult ({ userId, groupId, hid, filter, mine, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/consult/GetByParameters?hid=' + hid + '&user_id=' + userId + '&group_id=' + groupId + '&filter=' + filter + '&mine=' + mine

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostConsult ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/consult/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDrugs ({ rid, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/drugs/' + rid

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostDrugs ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/drugs',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetTestBySVID ({ sv_id, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/lab/GetByParameters?visit_id=0&sv_id=' + sv_id + '&id='

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetLabRes ({ pon_list, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/labres/GetByParameters?pon_list=' + pon_list

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetTrace ({ rid, groupId, token, traceStatus }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/trace/GetByParameters?rid=' + rid + '&group_id=' + groupId + '&trace_status=' + traceStatus

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostTrace ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/trace',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetShifts ({ date, groupList, groupId, userId, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/shifts/GetByParameters?group_id=' + groupId + '&user_group_list=' + groupList + '&start_date=' + date + '&ot_status=X&user_id=' + userId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetShiftsByStatus ({ date, groupList, groupId, userId, status, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/shifts/GetByParameters?group_id=' + groupId + '&user_group_list=' + groupList + '&start_date=' + date + '&ot_status=' + status + '&user_id=' + userId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetDuty ({ groupId, userGroup, month, year, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/duty/GetByParameters?group_id=' + groupId + '&user_group=' + userGroup + '&hu_status=A&mo=' + month + '&yr=' + year + '&dy=0'

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetUserList ({ userGroup, keyword, groupId }, cb, errorCb) {
  // console.log(getHeader(token))
  window.axios({
    method: 'get',
    url: URL + '/user/GetByParameters?user_id=0&user_group_list=' + userGroup + '&keyword=' + keyword + '&group_id=' + groupId

  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostShift ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/shifts/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetOperationList ({ visitId, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/claims/GetByParameters?visit_id=' + visitId + '&filter=1&id='
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostDuty ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/duty/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetHoliday ({ groupList, spec, month, year, token }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/holy/GetByParameters?group_list=' + groupList + '&spec=' + spec + '&mo=' + month + '&yr=' + year
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostHoliday ({ data, token }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/holy/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetFastTrack ({ userId, sympSearch, codeDiag, strucSearch, atc, groupId, drugSearch }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/fasttrack/GetByParameters?user_id=' + userId + '&symp_search=' + sympSearch + '&code_diag=' + codeDiag + '&struc_search=' + strucSearch + '&atc=' + atc + '&group_id=' + groupId + '&drug_search=' + drugSearch
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostInsurance ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/insurance',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetClaimingList ({ perStatusList, groupId, token, userId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/visits/GetByParameters?rid=0&user_id=' + userId + '&per_status_list=' + perStatusList + '&per_date=&visit_id=0&group_id=' + groupId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostFastTrack ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/fasttrack',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostUser ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/user',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetUserByKeyword ({ keyword }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/user/GetByParameters?user_id=0&user_group_list=&keyword=' + keyword + '&group_id='
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetVisits ({ userId, groupId, status }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/visits/GetByParameters?rid=0&user_id=' + userId + '&per_status_list=' + status + '&per_date=&visit_id=0&group_id=' + groupId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetClaims ({ visitId, filter, id }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/claims/GetByParameters?visit_id=' + visitId + '&filter=' + filter + '&id=' + id
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostClaims ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/claims',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetVsign ({ rid, startdate, enddate, limit }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/vsign/GetByParameters?rid=' + rid + '&mobile=0&start_dt=' + startdate + '&end_dt=' + enddate + '&device_timestamp=0&limit_result=' + limit
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostVsign ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/vsign',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostGroupInfo ({ data }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/group',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function GetStamp ({ group_id, user_group, user_id, focus_date }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/stamp/?group_id=' + group_id + '&user_group=' + user_group + '&user_id=' + user_id + '&focus_date=' + focus_date
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function PostStamp ({ data }, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/stamp',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function Bed (param) {
  return window.axios({
    method: 'get',
    url: URL + '/bed?group_id=' + param.groupId + '&admitting=' + param.admitting
  })
}

export function getVisit ({ datetime, groupId, userId }, cb, errorCb) {
  window.axios({
    method: 'get',
    url: URL + '/visits/GetByParameters?rid=0&user_id=' + userId + '&per_status_list=0|1|2|R&per_date=' + datetime + '&visit_id=0&group_id=' + groupId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postVisit (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL + '/visits/',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function searchICD10 ({ keyword }) {
  return window.axios({
    method: 'get',
    url: URL + '/diag/GetByParameters?visit_id=0&rid=0&cont=1&keyword=' + keyword
  })
}
