import { getItemMaster, postItemMaster, patchItemMaster, getDrugGroup, getRefMatgroup,getItemBun } from '@/api/func_app_api'

const state = () => ({

})

const mutations = {
  
}

const actions = {
  UPDATE_ITEM_MASTER({commit}, data) {
    return new Promise ((resolve, reject)=> {
      patchItemMaster(data, message=>{
        resolve(message.data)
      },error=> {
        reject(error)
      })
    })
  },
  CREATE_ITEM_MASTER({commit}, data) {
    return new Promise ((resolve, reject)=> {
      postItemMaster(data, message=> {
        resolve(message.data)
      })
    }, error=> {
        reject(error)
    })
  },
  GET_ITEM_BUN({commit}) {
    return new Promise ((resolve, reject)=> {
      getItemBun(message=>{
        resolve(message.data)
      },error=>{
        reject(error)
      })
    })
  },
  GET_REF_MATGROUP({commit}) {
    return new Promise((resolve, reject)=> {
      getRefMatgroup(message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      }) 
    })
  },
  GET_DRUG_GROUP ({commit}) {
    return new Promise ((resolve, reject)=> {
      getDrugGroup(message=> {
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  GET_ITEM_MASTER ({ commit }, param) {
    console.log('GET_ITEM_MASTER')
    return new Promise((resolve, reject) => {
        getItemMaster({page_size: param.page_size, page_no: param.page_no,keyword: param.keyword}, message=> {
            resolve(message.data)
        }, error=> {
            reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
